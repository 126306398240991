import {
	Breadcrumb,
	Svg,
	Card,
	Table,
	ExternalLink,
	Button,
	TextArea,
	Checkbox,
	RadioGroup,
	LoadingFullScreen,
	Dialog2,
} from 'components'
import { DateTime } from 'luxon'
import groupBy from 'lodash.groupby'

import { getFilm, getVoterFilm, updateVoterFilm, requestScreener, downloadEntryAttachment } from 'api/voterfilms'
import { getSubgroups } from 'api/users'

import infoIcon from 'assets/icons/outline/information-circle.svg'
import bookmarkIcon from 'assets/icons/outline/bookmark.svg'
import downloadIcon from 'assets/icons/outline/download.svg'

import { notification } from 'utils/notifications'

export const Film = ({ attrs: { id, tab, tabName, subteam, categoryId } }) => {
	const root = '/film'
	let film, voterFilm
	let statementOpen = flyd.stream(false)
	let selectedStatement
	let screenRequest, requesting

	const loadData = () => {
		getFilm(id).then((res) => {
			film = res
		})
		getVoterFilm(id).then((res) => {
			voterFilm = res
		})
	}
	loadData()

	let voterFilmSaving = false
	const voterFilmSave = () => {
		voterFilmSaving = true
		updateVoterFilm(id, voterFilm)
			.then((res) => {
				notification.success({ title: 'Feedback saved' })
				voterFilmSaving = false
			})
			.catch((err) => {
				if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
					Sentry.captureException(err)
				}
				notification.error({ title: 'Saving failed' })
				voterFilmSaving = false
			})
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Viewing',
							href: '/viewing',
						},
						{
							title: tabName ? tabName : 'Films',
							href: tab
								? `/viewing?tab=${tab}` +
								  (categoryId ? `&categoryId=${categoryId}` : '') +
								  (subteam ? `&subteam=${subteam}` : '')
								: null,
						},
						{
							title: film ? film.title : '...',
						},
					],
				}),
				m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
					m('.p-4.w-full', [
						m('.grid.grid-cols-3.lg:grid-cols-5.gap-6', [
							m('.col-span-2.lg:col-span-3', [
								film
									? m('.flex.flex-col', [
											m('.shadow.sm:rounded-md.bg-white.p-4.mb-6', [
												film.posterUrl &&
													m('img.float-right.pl-6', { style: 'width:300px', src: `${film.posterUrl}?w=600&fm=jpg` }),
												m('.text-2xl.mb-2', film.title),

												m('.text-base.mb-6', m.trust(film.description)),
												m('hr'),
												m('.font-bold.text-sm.mt-3', 'Running Time (mins)'),
												m('.text-base.mb-2', film.runningTime),
												m('hr'),
												m('.font-bold.text-sm.mt-3', 'Screening'),
												m('.text-base.mb-2', film.screeningInfo),
												film.screenerDates && m('.text-base.mb-2', film.screenerDates),
												film.screenerRestrictions && m('.text-base.mb-2', film.screenerRestrictions),
												film.screeningLink &&
													m(
														ExternalLink,
														{
															href: film.screeningLink,
														},
														'Watch Now'
													),
												film.screeningLinkPassword &&
													m('.flex.gap-3.mt-2.text-sm', [
														m('span.font-bold', 'Password:'),
														m('span', film.screeningLinkPassword),
													]),
												film.screeningInfo.indexOf('Secure online screener sent directly to BIFA voters') >= 0
													? m(
															Button,
															{
																type: 'button',
																size: 'sm',
																loading: requesting,
																classes:
																	(voterFilm && voterFilm.screenerRequestedDate) || requesting
																		? ['pointer-events-none', 'bg-gray-300']
																		: '',
																onclick: (e) => {
																	if (!requesting) {
																		requesting = true
																		requestScreener(film.id).then((data) => {
																			requesting = false
																			voterFilm.screenerRequestedDate = data.screenerRequestedDate
																			notification.success({
																				title: 'Request for screener sent',
																				description: 'Thank you, your request has been sent.',
																			})
																		})
																	}
																},
															},
															voterFilm && voterFilm.screenerRequestedDate
																? `Screener Requested ${DateTime.fromISO(
																		voterFilm.screenerRequestedDate
																  ).toLocaleString(DateTime.DATE_SHORT)}`
																: 'Request Screener'
													  )
													: null,
												voterFilm &&
													voterFilm.screenerRequestedDate &&
													m(
														'p.py-2.px-3.bg-yellow-100.text-sm.rounded-lg.mt-1',
														"Your screener request has been received and will be passed to the film's distributor; please note it may take up to 5 working days for the screener to be delivered to you."
													),
												m('.clear-both'),
											]),
											m('.shadow.sm:rounded-md.bg-white.p-4.col-span-2', [
												m('.text-xl.mb-2', 'Entries'),
												m('table.table.table-fixed.w-full', [
													m('thead', [
														m('tr.text-left', [
															m('th.bg-gray-50.p-1.w-2/5', 'Category'),
															m('th.bg-gray-50.p-1.w-2/5', 'People'),
															m('th.bg-gray-50.p-1.w-1/5', 'Subgroup Status'),
														]),
													]),
													m('tbody', [
														film.entries
															.filter((e) => {
																return !e.subgroupIsHidden && e.votingCategoryTypeId !== 6 && e.entryStatusId !== 4
															})
															.map((entry) => {
																return m('tr', [
																	m('td.p-1.border-b.border-gray-200', entry.votingCategoryName),
																	m(
																		'td.p-1.border-b.border-gray-200',
																		entry.entryPersonFilms
																			? m('ul.list-unstyled', [
																					entry.entryPersonFilms.map((person) => {
																						return m('li.list-item.text-sm', [
																							//m(icon, { iconName: 'user mr-1' }),
																							person.name,
																							person.character
																								? ` (${person.character})`
																								: person.role
																								? ` (${person.role})`
																								: null,
																						])
																					}),
																					entry.entryAttachments.length
																						? entry.entryAttachments.map((file) => {
																								return m('li.list-item', [
																									m(
																										Button,
																										{
																											type: 'button',
																											size: 'xs',
																											onclick() {
																												downloadEntryAttachment(file.id, file.name)
																											},
																										},
																										[m(Svg, { classes: ['w-4', 'h-4'] }, downloadIcon), file.name]
																									),
																								])
																						  })
																						: null,
																					entry.statement
																						? m(
																								Button,
																								{
																									type: 'button',
																									size: 'xs',
																									onclick() {
																										selectedStatement = entry.statement
																										statementOpen(true)
																									},
																								},
																								'Statement'
																						  )
																						: null,
																			  ])
																			: null
																	),
																	m(
																		'td.p-1.border-b.border-gray-200',
																		entry.viewerStatusName ? entry.viewerStatusName : null
																	),
																])
															}),
													]),
												]),
											]),
									  ])
									: m(LoadingFullScreen),
							]),
							m(
								'.lg:col-span-2.relative',
								m(
									Card,
									{
										classes: ['relative', 'sticky', 'top-0'],
										buttons: [
											m(
												Button,
												{
													variant: 'positive',
													type: 'button',
													onclick() {
														voterFilmSave()
													},
												},
												'Save Feedback'
											),
										],
										heading: { title: 'Your Feedback' },
									},
									[
										film && voterFilm
											? m('div', [
													voterFilm.isAssigned
														? m('.flex', [
																m(Svg, { classes: ['w-8', 'h-8'] }, bookmarkIcon),
																m('.text-sm.font-bold', 'Assigned'),
														  ])
														: null,
													m(
														'p.mb-4',
														'Please give your feedback to the film, by selecting your seen/conflicted status:'
													),

													m(RadioGroup, {
														label: '',
														horizontal: true,
														radioBig: true,
														labelBig: true,
														name: 'seenNotSeenConflicted',
														options: [
															{ label: 'Seen', value: 'seen', color: 'focus:ring-green-500 text-green-600' },
															{ label: 'Not Seen', value: 'not-seen' },
															{ label: 'Conflicted', value: 'conflicted', color: 'focus:ring-red-500 text-red-600' },
														],
														required: true,
														value: voterFilm.isConflicted ? 'conflicted' : voterFilm.isSeen ? 'seen' : 'not-seen',
														onchange(value) {
															if (value === 'conflicted') {
																voterFilm.isConflicted = true
																voterFilm.isSeen = false
															} else if (value === 'seen') {
																voterFilm.isConflicted = false
																voterFilm.isSeen = true
															} else {
																voterFilm.isConflicted = false
																voterFilm.isSeen = false
															}
															voterFilmSave()
														},
													}),
													voterFilm.isSeen && !voterFilm.isConflicted
														? m('.feedback.mt-5', [
																m('hr'),
																m(
																	'p.py-2',
																	'Please give your response to the categories this film has been entered for (rows with a green background indicate your subgroup):'
																),
																m(FeedbackTable, { film, voterFilm, save: voterFilmSave }),
																m('hr.mt-4'),
																m(TextArea, {
																	label: 'Your comments',
																	value: voterFilm.comments,
																	rows: 6,
																	name: 'comments',
																	oninput: (content) => {
																		voterFilm.comments = content
																	},
																}),
																m('.text-sm.text-gray-600.flex.gap-2', [
																	m(Svg, { classes: ['w-6', 'h-6'] }, infoIcon),
																	'These are confidential and only visible to you, the chair of your sub-group and a BIFA admin',
																]),
																m(Checkbox, {
																	label: 'Keep comments anonymous',
																	checked: voterFilm.isCommentsAnonymous,
																	onchange() {
																		voterFilm.isCommentsAnonymous = !voterFilm.isCommentsAnonymous
																	},
																}),
																film.entries[0].votingCategoryShortName === 'Short'
																	? [
																			m('hr.mt-4'),
																			m(TextArea, {
																				label: 'Feedback for entrants',
																				value: voterFilm.commentsForEntrants,
																				rows: 6,
																				name: 'commentsForEntrants',
																				oninput: (content) => {
																					voterFilm.commentsForEntrants = content
																				},
																			}),
																			m('.text-sm.text-gray-600.flex.items-center.gap-2', [
																				m(Svg, { classes: ['w-6', 'h-6'] }, infoIcon),
																				'All comments in this box will be anonymised',
																			]),
																	  ]
																	: null,
																voterFilmSaving &&
																	m(
																		'.loading-overlay.absolute',
																		{
																			style:
																				'top:0;left:0;right:0;bottom:0;z-index:99;padding-top:3rem;background:rgba(255,255,255,.5)',
																		},
																		[m(LoadingFullScreen)]
																	),
														  ])
														: null,
											  ])
											: m(LoadingFullScreen),
									]
								)
							),
						]),
					]),
				]),
				statementOpen() &&
					m(StatementDialog, {
						open: statementOpen,
						statement: selectedStatement,
					}),
			]
		},
	}
}

const StatementDialog = ({ attrs: { loading } }) => {
	loading = false

	return {
		view({ attrs: { statement, open } }) {
			return m(
				Dialog2,
				{
					open,
					heading: 'Statement',
					classes: ['md:max-w-2xl'],
					cancelLabel: 'Close',
				},
				[m('.flex', statement)]
			)
		},
	}
}

const FeedbackTable = ({ attrs: { film, voterFilm, save } }) => {
	let subgroups
	getSubgroups().then((p) => {
		subgroups = p
	})
	return {
		view({ attrs: { film, voterFilm } }) {
			let entries = groupBy(
				film.entries.filter((e) => {
					return !e.subgroupIsHidden && e.votingCategoryTypeId !== 6
				}),
				(x) => {
					return x.id
				}
			)
			return m('table.table-fixed.w-full', [
				m('thead.text-xs.bg-gray-100', [
					m('tr.text-left', [
						m('th.p-1.border.border-gray-200', 'Entry'),
						m('th.text-center.w-1/6.p-1.border.border-gray-200', 'Not a contender'),
						m('th.text-center.w-1/6.p-1.border.border-gray-200', 'More to see'),
						m('th.text-center.w-1/6.p-1.border.border-gray-200', 'All to see'),
					]),
				]),
				subgroups
					? m('tbody', [
							film.entries.map((e) => {
								if (
									subgroups
										.filter((s) => {
											return !s.isHidden
										})
										.map((s) => {
											return s.id
										})
										.includes(e.subgroupId)
								) {
									return m('tr.bg-green-100', [
										m('td.p-1.border-b.border-gray-200', [
											m('.text-sm', e.votingCategoryShortName),
											e.entryPersonFilms
												? m('.text-gray-400.text-xs', [
														e.entryPersonFilms
															.map((person) => {
																return (
																	person.name +
																	(person.character ? ` (${person.character})` : person.role ? ` (${person.role})` : '')
																)
															})
															.join(', '),
												  ])
												: null,
										]),
										m('td.text-center.p-1.border-b.border-gray-200', [
											m(NacRadio, {
												value: 1,
												entryId: e.id,
												save,
												voterFilm,
												color: 'focus:ring-red-500 text-red-600',
											}),
										]),
										m('td.text-center.p-1.border-b.border-gray-200', [
											m(NacRadio, {
												value: 2,
												entryId: e.id,
												save,
												voterFilm,
												color: 'focus:ring-orange-400 text-orange-500',
											}),
										]),
										m('td.text-center.p-1.border-b.border-gray-200', [
											m(NacRadio, {
												value: 3,
												entryId: e.id,
												save,
												voterFilm,
												color: 'focus:ring-green-500 text-green-600',
											}),
										]),
									])
								}
							}),
							film.entries.map((e) => {
								if (
									!subgroups
										.filter((s) => {
											return !s.isHidden
										})
										.map((s) => {
											return s.id
										})
										.includes(e.subgroupId)
								) {
									return m('tr.bg-white', [
										m('td.p-1.border-b.border-gray-200', [
											m('.block.text-sm', e.votingCategoryShortName),
											e.entryPersonFilms
												? m('.text-gray-400.text-xs', [
														e.entryPersonFilms
															.map((person) => {
																return (
																	person.name +
																	(person.character ? ` (${person.character})` : person.role ? ` (${person.role})` : '')
																)
															})
															.join(', '),
												  ])
												: null,
										]),
										m('td.p-1.text-center.border-b.border-gray-200', [
											m(NacRadio, {
												value: 1,
												entryId: e.id,
												save,
												voterFilm,
												color: 'focus:ring-red-500 text-red-600',
											}),
										]),
										m('td.p-1.text-center.border-b.border-gray-200', [
											m(NacRadio, {
												value: 2,
												entryId: e.id,
												save,
												voterFilm,
												color: 'focus:ring-orange-400 text-orange-500',
											}),
										]),
										m('td.p-1.text-center.border-b.border-gray-200', [
											m(NacRadio, {
												value: 3,
												entryId: e.id,
												save,
												voterFilm,
												color: 'focus:ring-green-500 text-green-600',
											}),
										]),
									])
								}
							}),
					  ])
					: null,
			])
		},
	}
}

const NacRadio = () => {
	return {
		view({ attrs: { value, voterFilm, entryId, save, color } }) {
			return m('.flex.items-center.justify-center', [
				m('input.h-4.w-4.border-gray-300', {
					type: 'radio',
					value,
					name: `entry-nacmtsats-${entryId}`,
					class: color ? color : 'focus:ring-indigo-500 text-indigo-600',
					checked: voterFilm.voterEntries.find((e) => {
						return e.entryId === entryId && e.viewerStatusId === value
					}),
					onchange() {
						var v = voterFilm.voterEntries.find((e) => {
							return e.entryId === entryId
						})
						if (v) v.viewerStatusId = value
						else voterFilm.voterEntries.push({ entryId: entryId, viewerStatusId: value })
						// save()
					},
				}),
			])
		},
	}
}
