import { request } from './request'

export const getRounds = () => {
	return request({
		url: `voting/rounds`,
		method: 'GET',
	})
}

export const getRoundSummary = (id) => {
	return request({
		url: `voting/rounds/${id}`,
		method: 'GET',
	})
}

export const getRoundFilms = (id) => {
	return request({
		url: `voting/rounds/${id}/films`,
		method: 'GET',
	})
}

export const getRoundEntries = (id) => {
	return request({
		url: `voting/rounds/${id}/entries`,
		method: 'GET',
	})
}

export const getRoundUser = (id) => {
	return request({
		url: `voting/rounds/${id}/user`,
		method: 'GET',
	})
}
export const getRoundVotingCategoryEntries = (id, categoryId) => {
	return request({
		url: `voting/rounds/${id}/category/${categoryId}/entries`,
		method: 'GET',
	})
}
