import { Breadcrumb, Card, Input, Dialog2 } from 'components'
import { DateTime } from 'luxon'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'

export const Screenings = () => {
	let screening = flyd.stream(false)
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Screenings',
						},
					],
				}),
				m('.my-4.px-4.grid.overflow-auto', [
					m('div', [
						m(
							Card,
							{
								header: { title: 'Screenings' },
							},
							m('div', {
								oncreate: (cal) => {
									let calendar = new Calendar(cal.dom, {
										plugins: [dayGridPlugin, googleCalendarPlugin],
										googleCalendarApiKey: 'AIzaSyA4e1nVh7chkK64GUmbW5uF7KCvGTrs61Y',
										events: {
											googleCalendarId: 'bifa.org.uk_umahjgla4f43vbabo8gh8s0mds@group.calendar.google.com',
											editable: false,
										},
										eventClick: function (info) {
											info.jsEvent.preventDefault()
											screening({
												Start: info.event.start,
												Date: DateTime.fromJSDate(info.event.start).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY),
												FilmTitle: info.event.title,
												Description: info.event.extendedProps.description,
												Venue: info.event.extendedProps.location,
											})
											m.redraw()
										},
									})

									calendar.render()
								},
							})
						),
					]),
				]),
				screening() &&
					m(
						Dialog2,
						{ open: screening, heading: 'Screening', cancelLabel: 'Close' },
						m('.screening.grid.gap-2', [
							m(Input, {
								label: 'Film',
								type: 'readonly2',
								value: screening().FilmTitle,
							}),
							m(Input, {
								label: 'Date',
								type: 'readonly2',
								value: screening().Date,
							}),
							m(Input, {
								label: 'Venue',
								type: 'readonly2',
								value: screening().Venue,
							}),
							m('.form-group.form-group-flex.form-group-static', [
								m('label.text-sm', 'Details'),
								m('div.py-1.px-2.bg-gray-50.rounded-md', [m.trust(screening().Description)]),
							]),
							// vnode.state.screening.current().Start > new Date()
							// 	? m(
							// 			'button.btn.btn-info',
							// 			{
							// 				type: 'button',
							// 				onclick: e => {
							// 					m.request({
							// 						method: 'POST',
							// 						url: `/api/screenings/ticket`,
							// 						data: vnode.state.screening.current(),
							// 						config: xhr => {
							// 							xhr.setRequestHeader(
							// 								'Authorization',
							// 								`Bearer ${localStorage.getItem('accessToken')}`
							// 							)
							// 						},
							// 					}).then(() => {
							// 						notifications.clear()
							// 						notifications.add({
							// 							title: 'Request sent',
							// 							message:
							// 								'Your request has been sent, we will contact you to confirm your attendance.',
							// 							type: 'success',
							// 						})
							// 						;(e.currentTarget || e.target).innerText = 'Request sent'
							// 						;(e.currentTarget || e.target).disabled = true
							// 						vnode.state.screening.current(false)
							// 					})
							// 				},
							// 			},
							// 			'Request ticket for screening'
							// 	  )
							// 	: null,
						])
					),
			]
		},
	}
}
