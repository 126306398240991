import { request } from './request'

export const getVoterFilmsSummary = () => {
	return request({
		url: `voting/voterfilms/summaries`,
		method: 'GET',
	})
}

export const getFilms = (params) => {
	return request({
		url: `voting/films`,
		method: 'GET',
		params,
	})
}

export const getFilm = (id) => {
	return request({
		url: `voting/films/${id}`,
		method: 'GET',
	})
}
export const getVoterFilm = (id) => {
	return request({
		url: `voting/voterfilms/${id}`,
		method: 'GET',
	})
}

export const updateVoterFilm = (filmId, voterFilm) => {
	return request({
		url: `voting/voterfilms/${filmId}`,
		method: 'PUT',
		body: voterFilm,
	})
}

export const requestScreener = (id) => {
	return request({
		url: `voting/voterfilms/${id}/request-screener`,
		method: 'POST',
	})
}

export const downloadEntryAttachment = (entryAttachmentId, name) => {
	return request({
		url: `voting/films/entry-file?entryAttachmentId=${entryAttachmentId}`,
		method: 'GET',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	})
		.then((res) => {
			const date = new Date()
			var anchor = document.createElement('a')
			anchor.download = name
			anchor.href = window.URL.createObjectURL(res)
			anchor.click()
		})
		.catch((res) => {
			console.log(res)
		})
}
