import { Breadcrumb, Input, Checkbox, Select, RadioGroup, TextArea, EditModelForm, EditFormSection } from 'components'
import { getVotingSettings, updateVotingSettings } from 'api/profile'
import { getAwardsYear } from 'api/awardsyear'
import { notification } from 'utils/notifications'
import times from 'lodash.times'

export const VotingSettings = () => {
	let profileLoading = false

	let votingSettings
	// getVotingSettings().then((x) => {
	// 	if (x) {
	// 		votingSettings = x

	// 		if (!votingSettings.awardsYearUser) {
	// 			votingSettings.awardsYearUser = {
	// 				isAvailable: null,
	// 				requestedSubgroups: '[]',
	// 				requestedSubgroupsArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	// 			}
	// 		} else {
	// 			votingSettings.awardsYearUser.requestedSubgroupsArr = votingSettings.awardsYearUser.requestedSubgroups
	// 				? JSON.parse(votingSettings.awardsYearUser.requestedSubgroups)
	// 				: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	// 		}
	// 	}
	// })

	let awardsYear
	getAwardsYear().then((x) => {
		awardsYear = x
	})

	const updateVotingPrefs = (e) => {
		e.preventDefault()
		const updatedSettings = {
			id: votingSettings.id,
			company: votingSettings.company,
			country: votingSettings.country,
			craftSpecialist: votingSettings.craftSpecialist,
			distributorsPostalAddress: votingSettings.distributorsPostalAddress,
			emailForBifaInfo: votingSettings.emailForBifaInfo,
			emailForVotingInfo: votingSettings.emailForVotingInfo,
			experience: votingSettings.experience,
			imdbLinkedIn: votingSettings.imdbLinkedIn,
			occupation: votingSettings.occupation,
			phoneNumber: votingSettings.phoneNumber,
			role: votingSettings.role,
			townCity: votingSettings.townCity,
			awardsYearUser: votingSettings.awardsYearUser,
			diAnswers: {
				id: votingSettings.diAnswers.id,
				answers: votingSettings.diAnswers.answers,
			},
		}
		updatedSettings.awardsYearUser.requestedSubgroups = JSON.stringify(
			votingSettings.awardsYearUser.requestedSubgroupsArr
		)
		updateVotingSettings(updatedSettings).then((res) => {
			notification.success({ title: 'Voting Preferences Saved' })
			m.route.set('/settings/voting/updated')
		})
	}

	const setFormAnswer = (question, val) => {
		votingSettings.diAnswers.answers.find((a) => {
			return a.questionId === question.id
		}).value = JSON.stringify(val)
		m.redraw()
	}

	const getFormAnswer = (questionId) => {
		return votingSettings.diAnswers.answers.find((a) => {
			return a.questionId === questionId
		}).value
			? JSON.parse(
					votingSettings.diAnswers.answers.find((a) => {
						return a.questionId === questionId
					}).value
			  )
			: ''
	}

	const isFormAnswerMatched = (question) => {
		const answer = getFormAnswer(question.visibleQuestionId)
		let response = false
		if (answer) {
			const matchValue = question.visibleQuestionValue

			if ((Array.isArray(answer) && answer.includes(matchValue)) || answer === matchValue) {
				response = true
			} else if (matchValue.indexOf('||')) {
				let matchValueArr = matchValue.split('||')
				matchValueArr.forEach((element) => {
					if ((Array.isArray(answer) && answer.includes(element)) || answer === element) {
						response = true
					}
				})
			}
		}
		return response
	}

	let choices = []

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Voting Preferences',
						},
					],
				}),
				votingSettings &&
					awardsYear &&
					m(
						EditModelForm,
						{
							onsubmit: updateVotingPrefs,
						},
						[
							m(
								EditFormSection,
								{
									heading: 'Step 1.',
									description: '',
									extra: null,
									noSave: votingSettings.awardsYearUser.isAvailable !== false,
								},
								[
									m('.grid.grid-cols-6.gap-6', [
										m('h4.col-span-4', 'Do you think that you will have time to commit to voting for BIFA this year?'),
										m(
											'p.col-span-4',
											'On average, we ask voters to watch about 20 - 25 feature films or 30 - 40 shorts from early summer to November.'
										),
										m('.col-span-4', [
											m(RadioGroup, {
												label: '',
												horizontal: true,
												labelBig: true,
												name: 'isAvailable',
												options: [
													{ label: 'Yes', value: true },
													{ label: 'No', value: false },
												],
												required: true,
												value: votingSettings.awardsYearUser.isAvailable,
												onchange(value) {
													votingSettings.awardsYearUser.isAvailable = value
													m.redraw()
												},
											}),
										]),
									]),
								]
							),
							votingSettings.awardsYearUser.isAvailable &&
								m(
									EditFormSection,
									{
										heading: 'Step 2. Preferences',
										description:
											'Please select, in order of preference (with 1 being your most desirable and 10 being your least desirable), which subgroups you would be interested in being a part of. Remember that by joining a subgroup you are committing to watching a large number of films in that category but are free to watch films in other categories as well if you want to.',
										extra: null,
										noSave: true,
									},
									[
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m('ul.list-group.list-group-flush.votes', {}, [
													times(10, (ix) => {
														return m(
															'li.list-group-item.flex.mb-2',
															{
																key: `pref_${ix}`,
															},
															[
																m('.flex.items-center.p-2.handle', [m('span.mt-4.w-6', ix + 1)]),
																awardsYear.subgroups
																	? m(Select, {
																			label: '',
																			search: false,
																			value: votingSettings.awardsYearUser.requestedSubgroupsArr[ix],
																			required: true,
																			name: 'subgroupId_' + ix,
																			instance(el) {
																				choices.push(el)
																			},
																			onchange(val) {
																				votingSettings.awardsYearUser.requestedSubgroupsArr[ix] = parseInt(val)

																				times(10, (ix) => {
																					let z = awardsYear.subgroups
																						.filter((option) => !option.isHidden)
																						.map((option) => {
																							return {
																								label: option.name,
																								value: option.id,
																								disabled:
																									votingSettings.awardsYearUser.requestedSubgroupsArr.includes(
																										option.id
																									) &&
																									votingSettings.awardsYearUser.requestedSubgroupsArr[ix] !== option.id,
																							}
																						})
																					choices[ix].setChoices(z, 'value', 'label', true)
																				})
																				m.redraw()
																			},
																			choices: awardsYear.subgroups
																				.filter((option) => !option.isHidden)
																				.map((option) => {
																					return {
																						label: option.name,
																						value: option.id,
																						disabled:
																							votingSettings.awardsYearUser.requestedSubgroupsArr.includes(option.id) &&
																							votingSettings.awardsYearUser.requestedSubgroupsArr[ix] !== option.id,
																					}
																				}),
																	  })
																	: null,
															]
														)
													}),
												]),
											]),
											m('.col-span-6', [
												m('p.mt-4.text-lg.font-bold', 'Meeting Information'),
												m(
													'p.mt-4',
													'All meetings will take place via Zoom and Subgroup meetings last approximately 90 minutes. You are strongly encouraged to make time to attend all your subgroup meetings to ensure you are involved in voting discussions and decisions. Only voters who have attended at least 2 of the 3 meetings will be invited to attend the BIFA ceremony afterparty.'
												),
												m('p.mt-4.text-lg.font-bold', '2024 Voter Briefing (60 mins) Fri 5th July 10-11am'),
												m(
													'p.mt-4',
													'All voters are invited to attend this refresher on the voting process. This meeting is recorded and will be made available after the briefing'
												),
												m('p.mt-4.text-lg.font-bold', 'Subgroup Meeting Dates and Times'),
												m(
													'p.mt-4',
													m.trust(
														'All subgroup meeting times for 2024 are also available <a class="underline" href="https://www.bifa.film/about/rules-and-eligibility/voter-duties-2024/" target="_blank">here</a> and once you are assigned a subgroup you will be sent calendar invites for all your subgroup meetings'
													)
												),
												m('.flex.flex-wrap.gap-2.mt-4', [
													awardsYear.subgroups
														.filter((sg) => !sg.isHidden)
														.map((sg) => {
															return m('.flex.w-full.py-1.px-2.bg-gray-100.rounded.text-sm.leading-tight', [
																m('div.w-1/2.pr-4', [m('strong', sg.name), m('br'), m('span.text-xs', sg.description)]),
																m(
																	'div.w-1/2.text-xs',
																	m.trust(sg.meetings ? sg.meetings.replaceAll('\n', '<br>') : '')
																),
															])
														}),
												]),
											]),
										]),
									]
								),

							votingSettings.awardsYearUser.isAvailable &&
								m(
									EditFormSection,
									{
										heading: 'Step 3. Your information',
										description:
											'This is the contact information we currently have on record for you. Please update this if it is not correct.',
										extra: null,
										noSave: true,
									},
									[
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6.md:col-span-3', [
												m(Input, {
													label: 'Email for BIFA correspondence',
													value: votingSettings.emailForBifaInfo,
													type: 'email',
													required: true,
													oninput(val) {
														votingSettings.emailForBifaInfo = val
													},
												}),
											]),
											m('.col-span-6.md:col-span-3', [
												m(Input, {
													label: 'Email for distributor correspondence',
													value: votingSettings.emailForVotingInfo,
													type: 'email',
													required: true,
													oninput(val) {
														votingSettings.emailForVotingInfo = val
													},
												}),
											]),
											m('.col-span-6.md:col-span-3', [
												m(Input, {
													label: 'Town/City',
													value: votingSettings.townCity,
													type: 'text',
													oninput(val) {
														votingSettings.townCity = val
													},
												}),
											]),
											m('.col-span-6.md:col-span-3', [
												m(Input, {
													label: 'Country',
													value: votingSettings.country,
													type: 'text',
													oninput(val) {
														votingSettings.country = val
													},
												}),
											]),
											m('.col-span-6', [
												m(Input, {
													label: 'Please tell us your current occupation (eg Director, Producer, Casting Director)',
													value: votingSettings.occupation,
													type: 'text',
													required: true,
													oninput(val) {
														votingSettings.occupation = val
													},
												}),
											]),
											m('.col-span-6', [
												m(Select, {
													label: 'Is your experience mainly in',
													search: false,
													required: true,
													value: votingSettings.experience,
													onchange(e) {
														votingSettings.experience = e
													},
													strValue: true,
													choices: [
														{ label: 'Select one...', value: '' },
														{ label: 'Fiction Film', value: 'Fiction Film' },
														{
															label: 'Documentary Film',
															value: 'Documentary Film',
														},
														{
															label: 'Equally both Fiction and Documentary film',
															value: 'Equally both Fiction and Documentary film',
														},
														{
															label: 'Not applicable (I don’t work in production or distribution)',
															value: 'Not applicable (I don’t work in production or distribution)',
														},
													],
												}),
											]),
											m('.col-span-6', [
												m(Select, {
													label: 'Please indicate if you are a Craft specialist:',
													search: false,
													required: true,
													value: votingSettings.craftSpecialist,
													onchange(e) {
														votingSettings.craftSpecialist = e
													},
													strValue: true,
													choices: [
														{ label: 'Select one...', value: '' },
														{ label: 'Editor', value: 'Editor' },
														{ label: 'Cinematographer', value: 'Cinematographer' },
														{ label: 'Effects', value: 'Effects' },
														{ label: 'Music Supervisor', value: 'Music Supervisor' },
														{ label: 'Composer', value: 'Composer' },
														{ label: 'Costume Designer', value: 'Costume Designer' },
														{ label: 'Production Designer', value: 'Production Designer' },
														{ label: 'Make-Up & Hair Designer', value: 'Make-Up & Hair Designer' },
														{ label: 'Sound Designer', value: 'Sound Designer' },
														{
															label: 'Not applicable (I am not a craft specialist)',
															value: 'Not applicable (I am not a craft specialist)',
														},
													],
												}),
											]),
											m('.col-span-6', [
												m(
													'p.text-xs.text-gray-600',
													'We may also require a mobile number for you to use for certain secure screener login processes for some distributors. If you are happy to share this with us and the distributors, please enter your mobile number here'
												),
												m(Input, {
													label: 'Mobile*',
													type: 'tel',
													value: votingSettings.phoneNumber,
													classes: 'max-w-xs',
													oninput(val) {
														votingSettings.phoneNumber = val
													},
												}),
												m(
													'p.text-xs.text-gray-600',
													'*Your mobile number will only be used as part of the secure login process for certain online screening services, who sign data agreements with BIFA regarding the use of such information.'
												),
											]),
										]),
									]
								),

							votingSettings.awardsYearUser.isAvailable &&
								m(
									EditFormSection,
									{
										heading: 'Step 4. Agreements',
										description: '',
										extra: null,
										noSave: true,
									},
									[
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m(
													'p',
													m.trust(
														`By ticking this box and registering to be a voter for the British Independent Film Awards 2024, all voters agree to abide by BIFA's Voter Agreement and Code of Conduct which can be found in full <a href='${
															awardsYear.voterAgreementLink
																? awardsYear.voterAgreementLink
																: 'https://storage.googleapis.com/bifa-film/web/2024/05/BIFA-Voter-Code-of-Conduct-2024.pdf'
														}' target='_blank' class="underline">here</a>.`
													)
												),

												m(Checkbox, {
													label: 'I agree',
													checked: votingSettings.awardsYearUser.isAgreeTerms,
													required: true,
													onchange() {
														votingSettings.awardsYearUser.isAgreeTerms = !votingSettings.awardsYearUser.isAgreeTerms
													},
												}),
												m('p.mt-4', 'Also, please confirm that'),
												m(Checkbox, {
													label:
														'I am happy to receive information about BIFA voting from BIFA using the details given above.',
													checked: votingSettings.awardsYearUser.isBIFAContactConsent,
													onchange() {
														votingSettings.awardsYearUser.isBIFAContactConsent =
															!votingSettings.awardsYearUser.isBIFAContactConsent
													},
												}),
												m(Checkbox, {
													label:
														'I would like to receive the BIFA newsletter, to learn more about BIFA activities/events, partner offers, industry opportunities and the latest in independent film news.',
													checked: votingSettings.awardsYearUser.isBIFANewsletterConsent,
													onchange() {
														votingSettings.awardsYearUser.isBIFANewsletterConsent =
															!votingSettings.awardsYearUser.isBIFANewsletterConsent
													},
												}),
												m(Checkbox, {
													label:
														'I am happy for BIFA to use my data to run analyses and research on the demographics and state of the British independent film industry.',
													checked: votingSettings.awardsYearUser.isBIFAResearch,
													onchange() {
														votingSettings.awardsYearUser.isBIFAResearch = !votingSettings.awardsYearUser.isBIFAResearch
													},
												}),
												m(
													'p.mt-3',
													m.trust(
														"We've updated our privacy policies so that we're GDPR compliant. You can read our privacy policy regarding information you give to us for the purposes of voting for BIFA <a href='https://www.bifa.film/privacy-policy/voting/' target='_blank'>here</a>."
													)
												),
												m(Checkbox, {
													label: `I would like to receive more information about BIFA's Equality, Diversity & Inclusion training courses, including Anti-Bullying and Harassment Training, Unconscious Bias and Fair Recruitment and Inclusivity in the Workplace Training.`,
													checked: votingSettings.awardsYearUser.isMoreEDICoursesInfo,
													onchange() {
														votingSettings.awardsYearUser.isMoreEDICoursesInfo =
															!votingSettings.awardsYearUser.isMoreEDICoursesInfo
													},
												}),
											]),
										]),
									]
								),

							votingSettings.awardsYearUser.isAvailable &&
								m(
									EditFormSection,
									{
										heading: 'Step 5. Consent',
										description: '',
										extra: null,
										noSave: true,
									},
									[
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m('p.font-bold', 'Eventive'),
												m(
													'p',
													'BIFA uses Eventive as a screening platform for the awards. To access any BIFA film online on Eventive you must have a BIFA pass, which is allocated to you via the Eventive platform using your name and email address. If you used Eventive to view BIFA films online in 2023 and the email address you have provided above is unchanged, your BIFA pass will be added to your Eventive account and you will be notified of this by email. If you do not have an Eventive account, you will be prompted to create one when your pass is issued.'
												),

												m(RadioGroup, {
													label: '',
													options: [
														{
															label:
																'I CONSENT to BIFA using my name and email address as provided to allocate my BIFA pass on Eventive',
															value: true,
														},
														{
															label:
																'I DO NOT CONSENT to BIFA using my name and email address as provided to allocate my BIFA pass on Eventive',
															value: false,
														},
													],
													required: true,
													value: votingSettings.awardsYearUser.isAgreeEventive,
													name: 'isAgreeEventive',
													onchange(value) {
														votingSettings.awardsYearUser.isAgreeEventive = value
													},
												}),
												m(
													'p.italic.text-sm',
													m(
														'a',
														{ href: 'https://eventive.org/privacy', target: '_blank' },
														`If you would like to read Eventive's privacy policy click here`
													)
												),
												m('p.font-bold.mt-4', 'Screener Requests'),
												m(
													'p',
													'Occasionally we need to send the contact details you provide to distributors for the purpose of providing you with individual access or links to films in contention in the awards.'
												),
												m(RadioGroup, {
													label: '',
													options: [
														{
															label:
																'I CONSENT to BIFA passing my contact details (email address, name, phone number) on to distributors, for the purposes of receiving screeners and invitations to screenings',
															value: true,
														},
														{
															label:
																'I DO NOT CONSENT to BIFA passing my contact details (email address, name, phone number) on to distributors, for the purposes of receiving screeners and invitations to screenings',
															value: false,
														},
													],
													required: true,
													value: votingSettings.awardsYearUser.isDistributorContactConsent,
													name: 'isDistributorContactConsent',
													onchange(value) {
														votingSettings.awardsYearUser.isDistributorContactConsent = value
													},
												}),
												m(
													'p.mt-4',
													'For strictly the above purpose only, occasionally this data needs to be sent outside of the UK, including to the US.'
												),
												m(RadioGroup, {
													label: '',
													options: [
														{
															label:
																'I CONSENT to my contact details (email address, name, phone number) being sent to distributors, where necessary, outside of the UK, including to the US',
															value: true,
														},
														{
															label:
																' I DO NOT CONSENT to my contact details (email address, name, phone number) being sent to distributors, where necessary, outside of the UK, including to the US',
															value: false,
														},
													],
													required: true,
													value: votingSettings.awardsYearUser.isDistributorContactOutsideUKConsent,
													name: 'isDistributorContactOutsideUKConsent',
													onchange(value) {
														votingSettings.awardsYearUser.isDistributorContactOutsideUKConsent = value
													},
												}),
												m(
													'p.italic.text-sm.mt-4',
													'Every distributor who handles your data is required to agree to handle voter data in line with the principles of GDPR. Distributors will dispose of your information once awards season is over.'
												),
												m(
													'p.italic.text-sm',
													'Please be advised that not giving consent will mean that we may not always be able to give you access to the films you wish to view.'
												),
												m(
													'p.italic.text-sm',
													'You may withdraw your consent at any time (by emailing hello@bifa.film)'
												),
											]),
										]),
									]
								),

							votingSettings.awardsYearUser.isAvailable &&
								m(
									EditFormSection,
									{
										heading: 'Step 6. Conflicts of Interest',
										description:
											'As part of the agreement to register to vote for BIFA, we ask you to declare any conflict of interest you might have.',
										extra: null,
										noSave: true,
									},
									[
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m('p', 'We consider a COI to be:'),
												m('ul.list-disc.list-inside', [
													m('li', 'if you worked on a film directly and have a credit'),
													m('li', 'currently are employed by a company who funded or distributed the film'),
													m('li', 'have a partner or relative who worked on the film.'),
												]),
												m(
													'p.mt-2.font-bold',
													'If you recognise a name or are acquainted with that individual, this does not necessarily constitute a COI. Of course, if you feel you can’t be impartial, please mark it as a conflict when voting.'
												),
												m('p.mt-2', 'At this stage, could you please just let us know the following:'),
											]),
											m('.col-span-6', [
												m(TextArea, {
													value: votingSettings.awardsYearUser.currentEmployers,
													label: 'Your current employer/employers',
													name: 'currentEmployers',
													rows: 3,
													oninput: (content) => {
														votingSettings.awardsYearUser.currentEmployers = content
													},
												}),
												m(
													'p.text-sm.mb-2.italic',
													'This can include in either full-time, part-time or freelance capacities.'
												),
											]),
											m('.col-span-6', [
												m(TextArea, {
													value: votingSettings.awardsYearUser.productionsLast2Years,
													label: 'Productions you have worked on in any capacity, in the last two years.',
													name: 'productionsLast2Years',
													rows: 3,
													oninput: (content) => {
														votingSettings.awardsYearUser.productionsLast2Years = content
													},
												}),
											]),
											m('.col-span-6.md:col-span-3', [
												m(Input, {
													label: 'If you have one, please provide your IMDB or Linkedin profile link',
													value: votingSettings.imdbLinkedIn,
													oninput(val) {
														votingSettings.imdbLinkedIn = val
													},
												}),
											]),
										]),
									]
								),
							votingSettings.awardsYearUser.isAvailable &&
								m(
									EditFormSection,
									{
										heading: 'Step 7. Unconscious Bias Training',
										description: `As a BIFA voter, you're required to attend our Unconscious Bias training every three voting years.`,
										extra: null,
										noSave: true,
									},
									[
										m('.grid.grid-cols-6.gap-6', [
											m('.col-span-6', [
												m(
													'p.mb-4',
													`As a BIFA voter, you're required to attend our Unconscious Bias training every three voting years. If you did the training in 2021 or earlier, or have not yet done the training, please ensure you register for a session.`
												),
												m(Select, {
													label: 'Please select when you last did the training:',
													search: false,
													required: true,
													value: votingSettings.awardsYearUser.unconsciousBiasStatus,
													onchange(e) {
														votingSettings.awardsYearUser.unconsciousBiasStatus = e
														if (e === '2022 voting year' || e === '2023 voting year') {
															votingSettings.awardsYearUser.isUnconsciousBiasTrainingComplete = true
														} else {
															votingSettings.awardsYearUser.isUnconsciousBiasTrainingComplete = false
														}
													},
													strValue: true,
													choices: [
														{ label: 'Select one...', value: '' },
														{ label: '2019 voting year', value: '2019 voting year' },
														{ label: '2020 voting year', value: '2020 voting year' },
														{ label: '2021 voting year', value: '2021 voting year' },
														{ label: '2022 voting year', value: '2022 voting year' },
														{ label: '2023 voting year', value: '2023 voting year' },
														{ label: 'Not sure', value: 'Not sure' },
														{ label: 'Never', value: 'Never' },
													],
												}),
											]),
										]),
									]
								),
							votingSettings.awardsYearUser.isAvailable &&
								m(
									EditFormSection,
									{
										heading: 'Step 8. Diversity & Inclusion Monitoring',
										description: ``,
										extra: null,
										noSave: false,
									},
									[
										m('.grid.gap-6', [
											m.trust(votingSettings.diAnswers.form.description),
											m('ol.list-group.space-y-4', [
												votingSettings.diAnswers.form.questions.map((question) => {
													return question.visibleQuestionId === null || isFormAnswerMatched(question)
														? m('li', {}, [
																(question.questionTypeId === 3 || question.questionTypeId === 5) &&
																	m('div', [
																		m(Select, {
																			label: question.name,
																			search: false,
																			subtype: question.questionTypeId === 5 ? 'multiple' : null,
																			required:
																				question.isRequired &&
																				!(
																					question.visibleQuestionId &&
																					!(getFormAnswer(question.visibleQuestionId) === question.visibleQuestionValue)
																				),
																			value: getFormAnswer(question.id),
																			onchange(val) {
																				setFormAnswer(question, val)
																			},
																			strValue: true,
																			choices: JSON.parse(question.options).map((option) => {
																				return { label: option, value: option }
																			}),
																		}),
																	]),
																question.questionTypeId === 1 &&
																	m(Input, {
																		label: question.name,
																		type: 'text',
																		required:
																			question.isRequired &&
																			!(
																				question.visibleQuestionId &&
																				!(getFormAnswer(question.visibleQuestionId) === question.visibleQuestionValue)
																			),
																		value: getFormAnswer(question.id),
																		oninput(val) {
																			setFormAnswer(question, val)
																		},
																	}),
																question.questionTypeId === 2 &&
																	m(TextArea, {
																		value: getFormAnswer(question.id),
																		label: question.name,
																		name: `sel_q_${question.id}`,
																		rows: 4,
																		required:
																			question.isRequired &&
																			!(
																				question.visibleQuestionId &&
																				!(getFormAnswer(question.visibleQuestionId) === question.visibleQuestionValue)
																			),
																		oninput: (content) => {
																			setFormAnswer(question, content)
																		},
																	}),
														  ])
														: null
												}),
											]),
										]),
									]
								),
						]
					),
			]
		},
	}
}
