import { Tabs } from 'components'
import { getRoundFilms, getRoundEntries } from 'api/rounds'
import { VotingTab } from './VotingTab'

export const Voting = ({ attrs: { id, roundVotingCategories, tab, profile } }) => {
	const root = `/rounds/${id}/films`
	if (!tab) {
		tab = roundVotingCategories[0].votingCategoryName
	}

	let tabs = roundVotingCategories.map((z) => {
		return { name: z.votingCategoryName, id: z.id, cat: z, active: tab === z.votingCategoryName }
	})
	let activeTab = tabs[0]

	return {
		view() {
			return m('.h-auto', [
				m(Tabs, {
					tabs,
					onTabChange: (tabId) => {
						activeTab = null
						tabs.forEach((t) => {
							if (t.id === tabId) {
								tab = t.name
								activeTab = t
								t.active = true
							} else {
								t.active = false
							}
						})
						m.redraw()
					},
				}),
				m('.relative.shadow.p-6.mb-2.bg-gray-50', [
					[
						activeTab
							? m(VotingTab, {
									key: activeTab.id,
									id,
									roundVotingCategory: activeTab.cat,
									profile,
							  })
							: null,
					],
				]),
			])
		},
	}
}
