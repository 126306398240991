import { Breadcrumb, Tabs, Card, Table, Cell, Button, Pagination, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'

export const Nav = ({ attrs: { id, awardsYearUserRound, round, tab } }) => {
	const root = `/rounds/${id}`

	return {
		view() {
			return [
				m(
					'nav.mb-6.[aria-label="Progress"]',
					m(
						'ol.divide-y.divide-gray-300.rounded-md.border.border-gray-300.bg-white.md:flex.md:divide-y-0[role="list"]',
						[
							m('li.relative.md:flex.md:flex-1', [
								m(
									m.route.Link,
									{
										href: root,
										class: 'group flex w-full items-center',
									},
									m('span.flex.items-center.px-6.py-4.text-sm.font-medium', [
										m(
											'span.flex.h-10.w-10.flex-shrink-0.items-center.justify-center.rounded-full',
											{
												class: tab === 'summary' ? 'bg-brand group-hover:bg-black' : 'group-hover:bg-gray-100',
											},
											tab === 'summary'
												? m(
														'svg.h-6.w-6.text-white[viewBox="0 0 24 24"][fill="currentColor"][aria-hidden="true"]',
														m(
															'path[fill-rule="evenodd"][d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"][clip-rule="evenodd"]'
														)
												  )
												: m(
														'span.flex.h-10.w-10.flex-shrink-0.items-center.justify-center.rounded-full.border-2.border-brand',
														m('span.text-brand', '01')
												  )
										),
										m('span.ml-4.text-sm.font-medium.text-brand', 'Round Summary'),
									])
								),
								m(
									'.absolute.right-0.top-0.hidden.h-full.w-5.md:block[aria-hidden="true"]',
									m(
										'svg.h-full.w-full.text-gray-300[viewBox="0 0 22 80"][fill="none"][preserveAspectRatio="none"]',
										m(
											'path[d="M0 -2L20 40L0 82"][vector-effect="non-scaling-stroke"][stroke="currentcolor"][stroke-linejoin="round"]'
										)
									)
								),
							]),
							m('li.relative.md:flex.md:flex-1', [
								m(
									m.route.Link,
									{
										href: `${root}/films`,
										class: 'group flex w-full items-center px-6 py-4',
									},
									[
										m(
											'span.flex.h-10.w-10.flex-shrink-0.items-center.justify-center.rounded-full.border-2.border-brand',
											{
												class: tab === 'films' ? 'bg-brand group-hover:bg-black' : 'group-hover:bg-gray-100',
											},
											tab === 'films'
												? m(
														'svg.h-6.w-6.text-white[viewBox="0 0 24 24"][fill="currentColor"][aria-hidden="true"]',
														m(
															'path[fill-rule="evenodd"][d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"][clip-rule="evenodd"]'
														)
												  )
												: m('span.text-brand', '02')
										),
										m('span.ml-4.text-sm.font-medium.text-brand', 'View films'),
									]
								),
								m(
									'.absolute.right-0.top-0.hidden.h-full.w-5.md:block[aria-hidden="true"]',
									m(
										'svg.h-full.w-full.text-gray-300[viewBox="0 0 22 80"][fill="none"][preserveAspectRatio="none"]',
										m(
											'path[d="M0 -2L20 40L0 82"][vector-effect="non-scaling-stroke"][stroke="currentcolor"][stroke-linejoin="round"]'
										)
									)
								),
							]),
							m('li.relative.md:flex.md:flex-1', [
								m(
									m.route.Link,
									{
										href: DateTime.fromISO(round.startDate) > DateTime.now() ? '' : `${root}/votes`,
										class: [
											'group flex w-full items-center px-6 py-4',
											DateTime.fromISO(round.startDate) > DateTime.now() ? 'pointer-events-none' : '',
										].join(' '),
									},
									[
										m(
											'span.flex.h-10.w-10.flex-shrink-0.items-center.justify-center.rounded-full.border-2',
											{
												class:
													tab === 'votes'
														? 'bg-brand group-hover:bg-black'
														: DateTime.fromISO(round.startDate) > DateTime.now()
														? 'border-gray-300 group-hover:border-gray-400'
														: 'border-brand',
											},
											[
												tab === 'votes'
													? m(
															'svg.h-6.w-6.text-white[viewBox="0 0 24 24"][fill="currentColor"][aria-hidden="true"]',
															m(
																'path[fill-rule="evenodd"][d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"][clip-rule="evenodd"]'
															)
													  )
													: m(
															'span',
															{
																class:
																	DateTime.fromISO(round.startDate) > DateTime.now()
																		? 'text-gray-500'
																		: 'text-brand group-hover:text-black',
															},
															'03'
													  ),
											]
										),
										m(
											'span.ml-4.text-sm.font-medium0',
											{
												class:
													DateTime.fromISO(round.startDate) > DateTime.now()
														? 'text-gray-500'
														: 'text-brand group-hover:text-black',
											},
											'Make your votes'
										),
									]
								),
								m(
									'.absolute.right-0.top-0.hidden.h-full.w-5.md:block[aria-hidden="true"]',
									m(
										'svg.h-full.w-full.text-gray-300[viewBox="0 0 22 80"][fill="none"][preserveAspectRatio="none"]',
										m(
											'path[d="M0 -2L20 40L0 82"][vector-effect="non-scaling-stroke"][stroke="currentcolor"][stroke-linejoin="round"]'
										)
									)
								),
							]),
							m(
								'li.relative.md:flex.md:flex-1',
								m(
									'a.group.flex.items-center',
									{
										href: DateTime.fromISO(round.startDate) > DateTime.now() ? '' : `${root}/submit`,
										class: DateTime.fromISO(round.startDate) > DateTime.now() ? 'pointer-events-none' : '',
									},
									m('span.flex.items-center.px-6.py-4.text-sm.font-medium', [
										m(
											'span.flex.h-10.w-10.flex-shrink-0.items-center.justify-center.rounded-full.border-2',
											{
												class:
													DateTime.fromISO(round.startDate) > DateTime.now()
														? 'border-gray-300 group-hover:border-gray-400'
														: 'border-brand',
											},
											[
												m(
													'span',
													{
														class:
															DateTime.fromISO(round.startDate) > DateTime.now()
																? 'text-gray-500'
																: 'text-brand group-hover:text-black',
													},
													'04'
												),
											]
										),
										m(
											'span.ml-4.text-sm.font-medium0',
											{
												class:
													DateTime.fromISO(round.startDate) > DateTime.now()
														? 'text-gray-500'
														: 'text-brand group-hover:text-black',
											},
											'Submit Votes'
										),
									])
								)
							),
						]
					)
				),
			]
		},
	}
}
