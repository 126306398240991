import { Tooltip, Svg } from 'components'
import alertIcon from 'assets/icons/outline/exclamation-circle.svg'
import yesIcon from 'assets/icons/solid/check-circle.svg'
import noIcon from 'assets/icons/outline/x-circle.svg'

export const EntryBadge = ({ attrs }) => {
	return {
		view({ attrs: { entry, size } }) {
			return m(
				'span.ml-3.inline-flex.items-center.px-2.5.py-0.5.rounded-full.font-medium',
				{
					class: ['bg-indigo-100 text-indigo-800', `text-${size}`].join(' '),
				},
				//[m(Tooltip, { tipContent: alert, icon: alertIcon, colorClass: 'bg-red-600 text-white' })]
				entry.votingCategoryShortName
			)
		},
	}
}

export const ViewerStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { entry, size } }) {
			return m(
				'.flex.items-center.px-2.5.py-0.5.rounded-full.font-medium',
				{
					class: [
						entry.viewerStatusId === 1
							? 'bg-red-200 text-red-800'
							: entry.viewerStatusId === 2
							? 'bg-orange-100 text-orange-800'
							: entry.viewerStatusId === 3
							? 'bg-orange-100 text-orange-800'
							: entry.viewerStatusId === 4
							? 'bg-orange-100 text-orange-800'
							: entry.viewerStatusId === 5
							? 'bg-indigo-100 text-indigo-800'
							: 'bg-white text-gray-800',
						,
						`text-${size}`,
					].join(' '),
				},
				//[m(Tooltip, { tipContent: alert, icon: alertIcon, colorClass: 'bg-red-600 text-white' })]
				entry.viewerStatus
			)
		},
	}
}
export const VotingViewerStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { entry, size } }) {
			return m(
				'.flex.items-center.px-2.5.py-0.5.rounded-full.font-medium',
				{
					class: [
						entry.voterViewerStatusId === 1
							? 'bg-red-200 text-red-800'
							: entry.voterViewerStatusId === 2
							? 'bg-orange-100 text-orange-800'
							: entry.voterViewerStatusId === 3
							? 'bg-orange-100 text-orange-800'
							: 'bg-white text-gray-800',
						,
						`text-${size}`,
					].join(' '),
				},
				//[m(Tooltip, { tipContent: alert, icon: alertIcon, colorClass: 'bg-red-600 text-white' })]
				entry.voterViewerStatus
			)
		},
	}
}

export const TrueFalseBadge = () => {
	return {
		view({ attrs: { trueFalse } }) {
			return m(
				Svg,
				{ classes: ['w-8', 'h-8', trueFalse ? 'text-green-800' : 'text-red-800'] },
				trueFalse ? yesIcon : noIcon
			)
		},
	}
}

export const EmailMessageStatusBadge = ({ attrs }) => {
	return {
		view({ attrs: { status, statusId, size } }) {
			return m(
				'span.ml-3.inline-flex.items-center.px-3.py-0.5.rounded-full.font-medium.text-sm',
				{
					class: [
						statusId === 0
							? 'bg-orange-300 text-orange-800'
							: statusId === 1
							? 'bg-green-300 text-green-800'
							: 'bg-red-300 text-red-800',
					],
				},
				status
			)
		},
	}
}
