import { NavbarUser } from './components/Navbar/NavbarUser'
import { Svg } from 'components'
import homeIcon from 'assets/icons/outline/home.svg'
import usersIcon from 'assets/icons/outline/users.svg'
import emailTemplatesIcon from 'assets/icons/outline/mail.svg'
import emailsIcon from 'assets/icons/outline/mail-open.svg'
import xIcon from 'assets/icons/solid/x.svg'
import menuIcon from 'assets/icons/solid/menu-alt-1.svg'
import awardsIcon from 'assets/icons/outline/trophy.svg'
import formsIcon from 'assets/icons/outline/document.svg'
import calIcon from 'assets/icons/outline/calendar.svg'
import filmsIcon from 'assets/icons/outline/film.svg'
import starIcon from 'assets/icons/outline/star.svg'
import cogIcon from 'assets/icons/outline/cog.svg'
import roundIcon from 'assets/icons/outline/adjustments.svg'

import logo from 'assets/logo.svg'

import { DisplayNotifications } from 'utils/notifications'
import { hasStoredToken } from 'store/auth'
import { getVersion, getConfig } from 'api/system'
import { getRounds } from 'api/rounds'

import { profile } from 'store/profile'

const NavLinks = ({ attrs: { version, profile, config, rounds } }) => {
	return {
		view() {
			return [
				m(
					'div.flex-shrink-0.flex.items-center.px-4',
					m(
						Svg,
						{
							classes: ['w-24'],
						},
						logo
					)
				),
				m("nav.mt-5.flex-shrink-0.divide-y.divide-gray-500.overflow-y-auto[aria-label='Sidebar']", [
					m('div.px-2.space-y-1', [
						m(NavLink, { href: '/', label: 'Dashboard', icon: homeIcon }),
						m(NavLink, { href: '/settings/voting', label: 'Voting Preferences', icon: cogIcon }),
					]),
					m('div.px-2.space-y-1.mt-2.pt-2', [m(NavLink, { href: '/screenings', label: 'Screenings', icon: calIcon })]),
					config() &&
						rounds() &&
						rounds().find((r) => {
							return r.number === 0
						}) &&
						m('div.px-2.space-y-1.mt-2', [
							m('p.text-sm.text-blue-200.ml-2.mt-2', `Film Viewing`),
							m(NavLink, { href: '/viewing?tab=all-films', label: 'Viewing', icon: starIcon }),
							m(NavLink, { href: '/viewing?tab=shorts', label: 'Viewing - Shorts', icon: starIcon }),
							m(NavLink, { href: '/viewing?tab=features', label: 'Viewing - Features', icon: starIcon }),
						]),
					config() &&
						rounds() &&
						m('div.px-2.space-y-1.mt-2', [
							m('p.text-sm.text-blue-200.ml-2.mt-2', `Rounds`),
							rounds().map((r) => {
								return r.number > 0 ? m(NavLink, { href: `/rounds/${r.id}`, label: r.name, icon: roundIcon }) : null
							}),
						]),
					m(
						'div.mt-6.pt-6',
						m('div.px-2.space-y-1', [
							m('p.text-xs.text-blue-200', `App: v${process.env.APP_VERSION}`),
							m('p.text-xs.text-blue-200', `Api: v${version}`),
						])
					),
				]),
			]
		},
	}
}
const NavLink = () => {
	return {
		view({ attrs: { label, href, icon } }) {
			return m(
				m.route.Link,
				{
					class: `group flex items-center px-2 py-1 text-sm font-medium ${
						href === m.route.get() || (href.length > 1 && m.route.get().indexOf(href) === 0)
							? 'text-white bg-black-500'
							: 'text-gray-100 hover:text-white hover:bg-blue-800'
					}`,
					href,
				},
				[icon && m(Svg, { classes: ['w-4', 'h-4', 'mr-4', 'text-gray-100'] }, icon), label]
			)
		},
	}
}

export const Layout = () => {
	let isSideNavOpen = false
	let isOnline = navigator.onLine

	window.ononline = () => {
		isOnline = true
		m.redraw()
	}

	window.onoffline = () => {
		isOnline = false
		m.redraw()
	}

	let version
	getVersion().then((s) => {
		version = s
	})
	const config = flyd.stream(false)
	const rounds = flyd.stream(false)
	if (hasStoredToken() && profile()) {
		getConfig().then((res) => {
			config(res)
		})
		getRounds().then((res) => {
			rounds(res)
		})
	}

	return {
		view({ children, attrs: { hideNav } }) {
			return m('div.h-screen.flex.overflow-hidden.bg-gray-100', [
				!hideNav &&
					m(
						'div.lg:hidden',
						{
							class: isSideNavOpen ? '' : 'hidden',
						},
						m('div.fixed.inset-0.flex.z-40', [
							m('div.fixed.inset-0', m(".absolute.inset-0.bg-gray-600.opacity-75[aria-hidden='true']")),
							m('div.relative.flex-1.flex.flex-col.max-w-xs.w-full.pt-5.pb-4.bg-brand', [
								m(
									'div.absolute.top-0.right-0.-mr-12.pt-2',
									m(
										'button.ml-1.flex.items-center.justify-center.h-10.w-10.rounded-full.focus:outline-none.focus:ring-2.focus:ring-inset.focus:ring-white',
										{
											type: 'button',
											onclick: () => {
												isSideNavOpen = false
											},
										},
										[m('span.sr-only', 'Close sidebar'), m(Svg, { classes: ['w-6', 'h-6'] }, xIcon)]
									)
								),
								version && m(NavLinks, { version: version, config, rounds }),
							]),
							m(".flex-shrink-0.w-14[aria-hidden='true']"),
						])
					),
				!hideNav &&
					m(
						'div.hidden.lg:flex.lg:flex-shrink-0',
						m('div.flex.flex-col.w-56.xl:w-64', [
							m('div.flex.flex-col.flex-grow.bg-brand.pt-5.pb-4.overflow-y-auto', [
								version && m(NavLinks, { version: version, config, rounds }),
							]),
							m('.text-xxs.bg-brand.text-blue-100', '© 2024 British Independent Film Awards C.I.C'),
						])
					),

				m(DisplayNotifications),
				m('.flex-1.overflow-auto.focus:outline-none[tabindex="0"]', [
					!hideNav &&
						m('.relative.z-10.flex-shrink-0.flex.h-16.bg-black.border-b.border-gray-200', [
							m(
								'button.px-4.border-r.border-gray-300.text-gray-800.focus:outline-none.focus:ring-2.focus:ring-inset.focus:ring-gray-500.lg:hidden',
								{
									type: 'button',
									onclick: () => {
										isSideNavOpen = true
									},
								},
								[m('span.sr-only', 'Open sidebar'), m(Svg, { classes: ['w-6', 'h-6'] }, menuIcon)]
							),
							m('.flex-1.px-4.flex.justify-between.sm:px-6.lg:mx-auto.lg:px-8', [
								m(
									'div.flex-1.flex.items-center.text-white',
									// m("form.w-full.flex.md:ml-0[action='#'][method='GET']",
									//   []
									// )
									process.env.APP_ALERT && [
										m('span.mr-3', process.env.APP_ALERT),
										m(
											'a.text-xs',
											{ href: `https://${process.env.API_DOMAIN}/`, target: '_blank' },
											`API: ${process.env.API_DOMAIN}`
										),
									]
								),
								m('div.ml-4.flex.items-center.md:ml-6', [
									hasStoredToken() && profile() && m('div.ml-3.relative', [m(NavbarUser)]),
								]),
							]),
						]),
					m(
						'main.flex-1.relative.z-0.flex.flex-col.overflow-hidden',
						{
							style: {
								height: hideNav ? '' : 'calc(100vh - 64px)',
							},
						},
						[children]
					),
				]),
				!isOnline &&
					m('.absolute.top-0.w-full.z-50', [
						m('.bg-red-500.top-0.w-auto.text-center.rounded-b.text-white.text-sm.shadow.p-2', [t('app', 'offline')]),
					]),
			])
		},
	}
}
