import { m } from 'mithril'
import { Input, Svg } from '../'
import searchIcon from 'assets/icons/outline/search.svg'
import arrowDown from 'assets/arrow-down-1.svg'
import tickIcon from 'assets/icons/outline/check.svg'
import crossIcon from 'assets/icons/outline/x.svg'

export const Table = () => {
	return {
		view({
			children,
			attrs: { cols, searchRow, searchString, onsearchinput, onsearch, sortBy, sortOrder, filter, fixed, filterRow },
		}) {
			return m('.h-full.overflow-y-scroll.w-full', [
				m(
					'table.w-full',
					{
						class: fixed ? 'table-fixed' : '',
					},
					[
						m('thead.bg-gray-50', [
							searchRow &&
								m('tr.bg-gray-400', [
									m(Cell, { colspan: cols.length, nopad: true }, [
										m(Input, {
											placeholder: t('app', 'search'),
											tighter: true,
											value: searchString,
											autofocus: true,
											oninput(val) {
												searchString = val
												onsearchinput(val)
											},
											onenter: onsearch,
											suffixButton: m(
												'button',
												{
													type: 'button',
													onclick: onsearch,
												},
												[
													m(
														Svg,
														{
															classes: ['text-gray-600', 'w-8', 'h-4'],
														},
														searchIcon
													),
												]
											),
										}),
									]),
								]),
							m('tr', [
								cols.map((col) => {
									return m(
										"th.sticky.top-0.z-10.h-8.px-2.py-2.text-xs.font-medium.border-b.border-gray-300.bg-gray-50.bg-opacity-75.backdrop-blur.backdrop-filter.text-gray-600.uppercase.tracking-wider[scope='col']",
										{
											class: [col.classes ? col.classes.join(' ') : ''].join(' '),
										},
										m(
											'div.flex.gap-x-1.items-center',
											{
												onclick: col.onclick ? col.onclick : null,
												class: [
													col.onclick ? 'cursor-pointer hover:text-black' : null,
													col.align ? `justify-${col.align}` : null,
												].join(' '),
											},
											[
												col.label,
												col.onclick &&
													col.sortBy === sortBy &&
													m(
														Svg,
														{
															title: sortOrder === 'desc' ? 'Descending' : 'Ascending',
															classes: ['text-gray-800', 'w-2', 'h-2', sortOrder === 'desc' ? 'rotate-180' : null],
														},
														arrowDown
													),
												col.onclick &&
													col.filter &&
													col.filter === filter &&
													m(
														Svg,
														{
															classes: ['text-gray-800', 'w-4', 'h-4', 'border', 'bg-white'],
														},
														tickIcon
													),
											]
										)
									)
								}),
							]),
							filterRow &&
								m('tr', [
									cols.map((col) => {
										return m(
											'th.h-6.px-2.py-1.text-xs.font-medium.border-b.border-gray-300.bg-gray-50',
											col.search &&
												m('input.py-0.px-3.w-full.text-xs.border-gray-300.bg-gray-100.focus:bg-white', {
													type: 'text',
													placeholder: col.searchPlaceholder || 'Search...',
													oninput: (e) => {
														col.searchOnChange(e.target.value)
													},
												}),
											col.filter &&
												col.filterOptions &&
												m(
													'select.py-0.pl-1.pr-6.w-full.text-xs',
													{
														onchange: (e) => {
															col.filterOptions.find((c) => {
																return c.selected
															}).selected = false
															col.filterOptions.find((c) => {
																return c.id == e.target.value
															}).selected = true
															col.filterOnChange(e.target.value)
														},
														style: 'min-width: max-content',
													},
													[
														col.filterEmptyOption && m('option', { value: '' }, ''),
														col.filterOptions.map((fo) => {
															return m('option', { value: fo.id, selected: fo.selected }, fo.name)
														}),
													]
												)
										)
									}),
								]),
						]),
						m('tbody.bg-white', children),
					]
				),
			])
		},
	}
}

export const Row = ({ attrs: { bg, onclick, style, rowId } }) => {
	return {
		view({ children }) {
			return m(
				'tr',
				{
					class: [onclick ? 'hover:bg-gray-300 cursor-pointer' : '', bg || 'bg-white'].join(' '),
					style,
					onclick: (e) => {
						if (onclick) {
							onclick(e, rowId)
						}
					},
				},
				children
			)
		},
	}
}

export const Cell = ({ attrs: { width, align, colspan, nopad, wrap, overflowShow } }) => {
	return {
		view({ children, attrs: { classes } }) {
			return m(
				'td.leading-tight.border-b.border-gray-200.text-sm.text-gray-900',
				{
					class: [
						width ? `sm:w-${width}` : null,
						align ? `text-${align}` : null,
						nopad ? 'px-[2px]' : 'px-2 py-1 sm:h-8',
						wrap ? '' : 'sm:whitespace-nowrap',
						classes ? classes.join(' ') : null,
						overflowShow ? 'overflow-visible' : 'overflow-hidden text-ellipsis whitespace-wrap',
					].join(' '),
					colspan: colspan || null,
				},
				[children]
			)
		},
	}
}

export const CellBool = ({ attrs: { width, align, colspan, nopad, wrap } }) => {
	return {
		view({ children, attrs: { classes, val, noFalse, color, strokeColor } }) {
			return m(Cell, { width, align: 'center', colspan, nopad, wrap, classes }, [
				m(
					'span',
					(!noFalse || val) &&
						m(
							Svg,
							{
								classes: [
									val ? 'w-6' : 'w-4',
									'sm:mx-auto',
									'inline',
									'sm:block',
									'rounded',
									'rounded-full',
									'p-1',
									val ? (color ? color : '') : 'text-gray-400',
									val ? (strokeColor ? strokeColor : '') : 'text-gray-400',
								],
							},
							val ? tickIcon : crossIcon
						)
				),
			])
		},
	}
}
