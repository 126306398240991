import { Breadcrumb, Card, Table, Cell, Button, Pagination, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'

import { getProfile } from 'api/profile'

export const Dashboard = () => {
	let email
	let profile
	getProfile().then((p) => {
		email = p.email
		profile = p
	})

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Dashboard',
						},
					],
				}),
				m('.my-4.px-4', [
					m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-sm.bg-white.px-4.py-3', [
						m('h1.text-lg', 'Welcome to BIFA Voting 2024'),
					]),
				]),
				m('.my-4.px-4.max-w-xl', [
					m(
						Card,
						{
							heading: { title: 'Voting' },
							buttons: [
								m(
									Button,
									{
										href: `/viewing?tab=shorts`,
									},
									`Short Films`
								),
								m(
									Button,
									{
										href: `/viewing?tab=features`,
									},
									`Feature Films`
								),
							],
						},
						[m('p', `Get started by viewing the 2024 films.`)]
					),
				]),
			]
		},
	}
}
