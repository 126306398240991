import { Breadcrumb, Tabs, Card, Table, Cell, Button, Pagination, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'
import { Nav } from './Nav'
import { getVotingSettings } from 'api/profile'
import { getRoundSummary, getRoundUser } from 'api/rounds'
import { ViewingTab } from './ViewingTab'
import { Voting } from './Voting'

export const Round = ({ attrs: { id, tab } }) => {
	let email
	let profile
	getVotingSettings().then((p) => {
		email = p.email
		profile = p
	})

	const root = `/rounds/${id}`
	let awardsYearUserRound
	let round

	const loadData = () => {
		getRoundSummary(id).then((res) => {
			round = res.round
		})
		getRoundUser(id).then((res) => {
			awardsYearUserRound = res
		})
	}
	loadData()

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Rounds',
						},
						{
							title: round ? round.name : '...',
						},
					],
				}),
				m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
					m('.p-4.w-full', [
						round && awardsYearUserRound && m(Nav, { id, awardsYearUserRound, round, tab }),
						m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', [
							round &&
								awardsYearUserRound &&
								m('div.bg-white.px-2.py-1', [
									tab === 'summary' &&
										m('.flex.flex-col.p-10.gap-6', [
											m('.text-xl', round.name),
											m('p.text-lg', round.description),
											m('hr'),
											DateTime.fromISO(round.startDate) > DateTime.now()
												? m(
														'p',
														`This round has not yet opened. It is scheduled to start on: ${DateTime.fromISO(
															round.startDate
														).toLocaleString(DateTime.DATETIME_HUGE)}`
												  )
												: m(
														'p',
														`Round ends: ${DateTime.fromISO(round.endDate).toLocaleString(DateTime.DATETIME_MED)}`
												  ),
											awardsYearUserRound.isAbstaining
												? m('.alert.alert-warning', 'You are abstaining from this round.')
												: null,
											m('.flex', [
												m(
													Button,
													{
														size: 'lg',
														type: 'button',
														variant: 'positive',
														href: `${root}/films`,
													},
													'View all films entered into this round'
												),
											]),
											awardsYearUserRound.isComplete &&
												m('.alert.alert-success.mt-5', 'Thank you for submitting your votes.'),
										]),
									tab === 'films' &&
										m(ViewingTab, { id, roundVotingCategories: round.roundVotingCategories, awardsYearUserRound }),
									tab === 'votes' &&
										m(Voting, { id, roundVotingCategories: round.roundVotingCategories, awardsYearUserRound, profile }),
								]),
						]),
					]),
				]),
			]
		},
	}
}
