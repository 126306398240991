import ClientOAuth2 from 'client-oauth2'

var bifaAuth = new ClientOAuth2({
	clientId: 'BIFAvoting',
	clientSecret: 'first-party',
	accessTokenUri: `https://${process.env.API_DOMAIN}/connect/token`,
	scopes: ['openid', 'email', 'phone', 'profile', 'offline_access', 'roles', 'BIFA_api'],
})

const auth = {
	pending: flyd.stream(false),
} // jlo

export function getToken(email, password) {
	auth.pending(true)
	tokenPromise = bifaAuth.owner.getToken(email, password)

	return tokenPromise
		.then((val) => {
			setToken(val)
			auth.pending(false)
		})
		.catch((err) => {
			auth.pending(false)
			throw Error(err)
		})
}

export function setToken(token) {
	localStorage.setItem(
		'token',
		JSON.stringify({
			token: token.data,
			expiresAt: token.expires,
		})
	)
	// Send api call to log browser, ip, userid
}

export async function refreshAuthToken() {
	return token()
		.refresh()
		.then((token) => {
			setToken(token)
			return token
		})
}

const retry = (fn, ms = 500, maxRetries = 6, retries = 0) =>
	new Promise((resolve, reject) => {
		if (retries === maxRetries) {
			reject(Error('maximum retries exceeded, aborted'))
		} else {
			return fn()
				.then(resolve)
				.catch((err) => {
					if (err.message.includes('aborted')) {
						setTimeout(() => {
							console.log(`retrying failed promise... ${retries + 1}`)
							++retries
							retry(fn, ms * 1.25, maxRetries, retries)
								.then(resolve)
								.catch(() => {
									tokenPromise = null
									refreshing = false
								})
						}, ms)
					} else {
						reject(Error(err))
					}
				})
		}
	})

let tokenPromise
let refreshing

export async function refreshAuthTokenOrWait() {
	console.log(
		`TOKEN INFO: hasStoredToken:${hasStoredToken() ? '1' : '0'} tokenPromise:${tokenPromise ? '1' : '0'} refreshing:${
			refreshing ? '1' : '0'
		} `
	)
	if (!hasStoredToken()) {
		return Promise.reject(Error('No Token'))
	} else if (!tokenPromise || (!hasValidToken() && !refreshing)) {
		refreshing = true
		tokenPromise = retry(refreshAuthToken)

		tokenPromise
			.then(() => {
				refreshing = false
			})
			.catch(() => {
				tokenPromise = null
				refreshing = false
			})
	} else if (!refreshing) {
		tokenPromise = Promise.resolve(token())
	}

	return tokenPromise
}

export function token() {
	if (localStorage.getItem('token') !== null) {
		const localStorageToken = JSON.parse(localStorage.getItem('token'))
		if (localStorageToken) {
			const authToken = bifaAuth.createToken(localStorageToken.token)
			authToken.expiresIn(new Date(localStorageToken.expiresAt))
			return authToken
		}
	} else {
		return null
	}
}

export function authIsPending() {
	return auth.pending()
}

export function hasStoredToken() {
	return !!token()
}

export function hasValidToken() {
	return hasStoredToken() && !tokenIsExpired()
}

export function tokenIsExpired() {
	if (token().expired()) return true
	else return false
}

export function clearAuth() {
	console.log('removing token')
	localStorage.removeItem('token')
	auth.pending(false)
}
