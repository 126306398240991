import { Svg } from 'components'
import linkIcon from 'assets/icons/outline/arrow-right-on-square.svg'

export const Link = () => {
	return {
		view({ children, attrs: { href, disabled, classes = [], options = {}, stopPropagation } }) {
			return m(
				m.route.Link,
				{
					href,
					disabled: false,
					options,
					class: ['hover:underline', ...classes].join(' '),
					onclick(e) {
						if (stopPropagation) {
							e.stopPropagation()
						}
					},
				},
				children
			)
		},
	}
}

export const ExternalLink = () => {
	return {
		view({ children, attrs: { href, classes = [], stopPropagation } }) {
			return m(
				'a.inline-flex.items-center.py-1.px-2.text-sm.rounded-sm.bg-blue-500.hover:bg-blue-400.text-white.border-blue-700.hover:border-blue-500.gap-2',
				{
					href,
					target: '_blank',
					class: classes.join(' '),
					onclick(e) {
						if (stopPropagation) {
							e.stopPropagation()
						}
					},
				},
				[children, m(Svg, { classes: ['w-4', 'h-4'] }, linkIcon)]
			)
		},
	}
}
