import { Radio } from 'components/Radio'

export const RadioGroup = () => {
	return {
		view({ attrs: { label, value, options, name, required, errorMessage, onchange, horizontal, labelBig, radioBig } }) {
			return m('.fieldset', [
				m('legend.text-base.font-medium.text-gray-900', label),
				m(
					'.mt-4.flex.justify-around',
					{
						class: horizontal ? 'gap-8' : 'flex-col',
					},
					[
						options.map((o) => {
							return m(Radio, {
								label: o.label,
								value: o.value,
								desc: o.desc,
								name,
								checked: o.value === value,
								required,
								onchange,
								labelBig,
								radioBig,
								color: o.color,
							})
						}),
					]
				),
				m('.text-red-600.text-sm.ml-10', errorMessage),
			])
		},
	}
}
