import { Table, Row, Cell, CellBool, Button, Svg, LoadingFullScreen } from 'components'
import { getRoundFilms, getRoundEntries } from 'api/rounds'
import groupBy from 'lodash.groupby'
import { DateTime } from 'luxon'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const ViewingTab = ({ attrs: { id, roundVotingCategories, categoryId, subteam } }) => {
	const root = `/rounds/${id}/films`
	let list

	let subTeam = subteam

	let categories = roundVotingCategories.map((z) => {
		return { name: z.shortName, id: z.id, selected: z.id == categoryId }
	})

	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}`
		if (categoryId) {
			pageUrl += `&categoryId=${categoryId}`
		}
		if (subTeam) {
			pageUrl += `&subteam=${subTeam}`
		}
		window.history.pushState('', '', pageUrl)
		getRoundFilms(id).then((res) => {
			getRoundEntries(id).then((entries) => {
				list = res.map((x) => {
					x.entries = entries.filter((m) => m.entry.filmId === x.id)
					return x
				})
			})
		})
	}

	loadData()

	let cols = [
		{
			label: 'Title',
			classes: ['w-48'],
			// sortBy: 'filmTitle',
			// onclick(e) {
			// 	toggleSortOrder('filmTitle')
			// },
		},
		{ label: 'Screener', classes: ['w-32'] },
		{ label: 'Duration', classes: ['w-24'], align: 'center' },
		{
			label: 'Entries',
			// filter: true,
			// filterEmptyOption: true,
			// filterOptions: categories,
			// filterOnChange(val) {
			// 	category = parseInt(val)
			// 	loadData()
			// },
			classes: ['w-64'],
		},

		{ label: 'Seen?', classes: ['w-24'], align: 'center' },
		{
			label: 'Conflict?',
			classes: ['w-24'],
			align: 'center',
		},
	]

	return {
		view() {
			return m('.h-auto', [
				categories
					? m(
							Table,
							{
								cols: cols,
							},
							[
								list
									? list.map((row) => {
											let entries = groupBy(row.entries, (x) => {
												return x.id
											})
											return m(
												Row,
												{
													clickable: false,
													// onclick: (e) => {
													// 	let pageUrl = `/film/${row.id}?tabName=${tab.name}&tab=${tab.name
													// 		.toLowerCase()
													// 		.replaceAll(' ', '-')}`
													// 	if (categoryId) {
													// 		pageUrl += `&categoryId=${category}`
													// 	}
													// 	if (subTeam) {
													// 		pageUrl += `&subteam=${subTeam}`
													// 	}
													// 	if (e.ctrlKey || e.metaKey) {
													// 		window.open([pageUrl], '_blank')
													// 	} else {
													// 		m.route.set(pageUrl)
													// 	}
													// },
													bg: 'bg-white',
												},
												[
													m(Cell, row.title),
													m(Cell, row.screener),
													m(Cell, { align: 'center' }, row.runningTime),
													m(
														Cell,
														Object.keys(entries).map((x) => {
															return m('span.text-xs.truncate.w-48', [
																entries[x][0].entry.votingCategoryShortName,
																entries[x][0].entry.person
																	? ` (${entries[x]
																			.map((z) => {
																				return z.entry.person
																			})
																			.join(', ')})`
																	: '',
															])
														})
													),
													m(CellBool, {
														val: row.isSeen,
														noFalse: false,
														color: 'bg-green-400',
														strokeColor: 'text-white',
													}),
													m(CellBool, {
														val: row.isConflicted,
														noFalse: true,
														color: 'bg-red-400',
														strokeColor: 'text-white',
													}),
												]
											)
									  })
									: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
							]
					  )
					: m(LoadingFullScreen),
			])
		},
	}
}
