import { Table, Row, Cell, CellBool, Button, Svg, LoadingFullScreen } from 'components'
import { getFilms } from 'api/voterfilms'
import { getAwardsYear } from 'api/awardsyear'
import groupBy from 'lodash.groupby'
import { DateTime } from 'luxon'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'
import { VotingViewerStatusBadge, ViewerStatusBadge } from 'components/Badge'

export const ViewingTab = ({ attrs: { tab, categoryId, subteam } }) => {
	const root = '/viewing'
	let list

	let categories
	let category = categoryId
	let subTeam = subteam
	let isHideSubgroupStatus

	getAwardsYear().then((res) => {
		categories = res.votingCategories
			.filter((z) => {
				return z.votingCategoryTypeId !== 6
			})
			.map((z) => {
				return { name: z.shortName, id: z.id, selected: z.id == category }
			})
		isHideSubgroupStatus = res.isHideSubgroupStatus
		cols[4].filterOptions = categories
	})
	const loadData = () => {
		list = null
		var pageUrl = `${window.location.pathname}?tab=${tab.name.toLowerCase().replaceAll(' ', '-')}`
		if (category) {
			pageUrl += `&categoryId=${category}`
		}
		if (subTeam) {
			pageUrl += `&subteam=${subTeam}`
		}
		window.history.pushState('', '', pageUrl)
		getFilms({
			type: tab.type,
			category: tab.category,
			categoryId: category ? category : '',
			subTeam: subTeam ? subTeam.toUpperCase() : '',
		}).then((res) => {
			list = res
		})
	}

	let cols = [
		{
			label: 'Title',
			classes: ['w-48'],
		},
		{ label: 'Screener', classes: ['w-32'] },
		{ label: 'Duration', classes: ['w-24'], align: 'center' },
		{ label: 'Total Seen', classes: ['w-24'], align: 'center' },
		{
			label: 'Entries',
			filter: true,
			filterEmptyOption: true,
			filterOptions: categories,
			filterOnChange(val) {
				category = parseInt(val)
				loadData()
			},
			classes: ['w-64'],
		},
		{ label: 'Seen?', classes: ['w-24'], align: 'center' },
		{
			label: 'Conflict?',
			classes: ['w-24'],
			align: 'center',
		},
	]
	if (tab.type === 'Subgroup') {
		cols.splice(4, 1, { label: 'Subgroup Entries', classes: ['w-32'], align: 'left', filter: false })
		cols.splice(5, 0, { label: 'Your Status', classes: ['w-24'], align: 'center' })
		if (!isHideSubgroupStatus) {
			cols.splice(5, 0, { label: 'Subgroup Seen', classes: ['w-24'], align: 'center' })
			cols.splice(6, 0, { label: 'Subgroup Status', classes: ['w-24'], align: 'center' })
			cols[0].classes = ['w-32']
		}
	}
	if (tab.name == 'All Films') {
		cols.push({ label: 'Status', classes: ['w-24'] })
	}
	if (tab.name == 'Shorts') {
		cols.push({
			label: 'SubTeam',
			classes: ['w-24'],
			align: 'center',
			filter: true,
			filterEmptyOption: true,
			filterOptions: Array.from('abcdefgh').map((l) => {
				return { id: l, name: l.toUpperCase(), selected: l === subTeam }
			}),
			filterOnChange(val) {
				subTeam = val
				loadData()
			},
		})
	}

	return {
		view() {
			return m('.h-auto', [
				categories
					? m(
							Table,
							{
								cols: cols,
								oncreate({ dom }) {
									loadData()
								},
								filterRow: true,
							},
							[
								list
									? list.map((row) => {
											let entries
											if (tab.type === 'Subgroup') {
												entries = groupBy(
													row.entries.filter((z) => {
														return z.votingCategoryTypeId !== 6 && z.subgroupName === tab.category
													}),
													(x) => {
														return x.entryId
													}
												)
											} else {
												entries = groupBy(
													row.entries.filter((z) => {
														return z.votingCategoryTypeId !== 6
													}),
													(x) => {
														return x.votingCategoryShortName
													}
												)
											}
											return m(
												Row,
												{
													clickable: true,
													onclick: (e) => {
														let pageUrl = `/film/${row.id}?tabName=${tab.name}&tab=${tab.name
															.toLowerCase()
															.replaceAll(' ', '-')}`
														if (categoryId) {
															pageUrl += `&categoryId=${category}`
														}
														if (subTeam) {
															pageUrl += `&subteam=${subTeam}`
														}
														if (e.ctrlKey || e.metaKey) {
															window.open([pageUrl], '_blank')
														} else {
															m.route.set(pageUrl)
														}
													},
													bg: 'bg-white',
												},
												[
													//m(Cell, row.isNew),
													m(Cell, row.title),
													m(Cell, row.screener),
													m(Cell, { align: 'center' }, row.runningTime),
													m(Cell, { align: 'center' }, row.totalSeen),
													m(
														Cell,
														m(
															'.flex.flex-wrap.gap-1',
															{
																class: tab.type === 'Subgroup' ? 'flex-col w-48' : 'flex-row',
															},
															[
																tab.type === 'Subgroup'
																	? Object.keys(entries).map((x) => {
																			return m('span.text-xs.truncate.w-48', [
																				entries[x][0].votingCategoryShortName,
																				entries[x][0].person
																					? ` (${entries[x]
																							.map((z) => {
																								return z.person
																							})
																							.join(', ')})`
																					: '',
																			])
																	  })
																	: Object.keys(entries).map((x) => {
																			let gEntries = groupBy(entries[x], (y) => {
																				return y.entryId
																			})
																			return m(
																				'span.text-xs.bg-gray-500.text-white.rounded.px-1.py-1/2',
																				`${x} ${
																					Object.keys(gEntries).length > 1 ? `(${Object.keys(gEntries).length})` : ''
																				}`
																			)
																	  }),
															]
														)
													),
													tab.type === 'Subgroup' && m(Cell, { align: 'center' }, row.subgroupSeen),
													tab.type === 'Subgroup' &&
														m(
															Cell,
															{ align: 'center' },
															Object.keys(entries).map((x) => {
																return m(ViewerStatusBadge, { entry: entries[x][0], size: 'xs' })
															})
														),
													tab.type === 'Subgroup' &&
														m(
															Cell,
															{ align: 'center' },
															Object.keys(entries).map((x) => {
																return m(VotingViewerStatusBadge, { entry: entries[x][0], size: 'xs' })
															})
														),
													m(CellBool, {
														val: row.isSeen,
														noFalse: true,
														color: 'bg-green-400',
														strokeColor: 'text-white',
													}),
													m(CellBool, {
														val: row.isConflicted,
														noFalse: true,
														color: 'bg-red-400',
														strokeColor: 'text-white',
													}),
													tab.name == 'All Films' && m(Cell, { align: 'center' }, ''),
													tab.name == 'Shorts' && m(Cell, { align: 'center' }, row.subTeam),
												]
											)
									  })
									: m(Row, [m(Cell, { align: 'center', colspan: cols.length }, m(LoadingFullScreen))]),
							]
					  )
					: m(LoadingFullScreen),
			])
		},
	}
}
