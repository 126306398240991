import { Breadcrumb } from 'components'

export const VotingSettingsUpdated = () => {
	let profileLoading = false

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							href: '/settings/voting',
							title: 'Voting Preferences',
						},
						{
							title: 'Updated',
						},
					],
				}),
				m('.my-4.px-4.max-w-3xl', [
					m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg.bg-white.px-4.py-3', [
						m('h1.text-lg', 'Thank you for updating your voting preferences'),
					]),
				]),
				m('.my-4.px-4.max-w-3xl', [
					m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg.bg-white.px-4.py-3', [
						m(
							'p.mb-3',
							'If you are able to, we would like to ask you to consider making a small donation to support BIFA.'
						),
						m(
							'p.mb-3',
							'We are a community interest company, meaning that we are run not for profit and with all income used to promote, champion and celebrate independent film and filmmakers year-round.'
						),
						m(
							'p.mb-3',
							'We have no core funding and all of our activity is dependent on the ceremony (via ticket sales, entry fees and a loyal group of partners) or on small project funds. Currently that income does not cover the costs of running the organisation and its activity all year round.'
						),
						m(
							'p.mb-3',
							'We are immeasurably grateful to our voters for their time and dedication without whom the awards would not happen. Donations are entirely voluntary, but all contributions will help BIFA continue its work championing independent British film and talent now and in the future.'
						),
						m(
							'p.mb-3',
							'If your company would like to explore becoming a BIFA partner, please let us know and we can send some more information.'
						),
						m(
							'p.mb-3',
							m.trust(
								`If you'd like to make a donation you can do so <a href="https://www.bifa.film/about/support-bifa/" class="underline" target="_blank">here</a>.`
							)
						),
						m('p', 'Thank you!'),
					]),
				]),
			]
		},
	}
}
