export const Checkbox = () => {
	return {
		view({ attrs: { name, label, value, checked, required, requiredWarning, onchange, classes = [] } }) {
			name = name || label ? label.replaceAll(' ', '_').toLowerCase() : 'n' + Date.now()
			return m('div', [
				m('.relative.flex.items-start.my-1', [
					m(
						'.flex.h-6.items-center',
						m('input', {
							type: 'checkbox',
							value,
							id: name,
							checked,
							required: required ? 'required' : null,
							classname: 'h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600',
							onclick(e) {
								e.stopPropagation()
								onchange(value)
							},
						})
					),
					m(
						'.ml-3.text-md.leading-6',
						{
							onclick(e) {
								e.stopPropagation()
								onchange(value)
							},
						},
						[
							m(
								'label.font-medium.text-gray-900',
								{
									for: name,
								},
								label
							),
						]
					),
				]),
				(required || requiredWarning) && m('span.text-xs.text-gray-500.uppercase', 'Required'),
			])
		},
	}
}
