export const Radio = () => {
	return {
		view({ attrs: { label, value, desc, checked, required, name, onchange, labelBig, radioBig, color } }) {
			return m('.flex.flex-col.mb-1', [
				m('.flex.items-center', [
					m('input.border-gray-300', {
						type: 'radio',
						value,
						name,
						checked,
						required: required ? 'required' : null,
						onclick(e) {
							onchange(value)
						},
						class: [radioBig ? 'w-6 h-6' : 'w-4 h-4', color ? color : 'focus:ring-indigo-500 text-indigo-600'].join(
							' '
						),
					}),
					m(
						'label.ml-4',
						{
							onclick(e) {
								onchange(value)
							},
						},
						[
							m(
								'span.block.font-medium.text-gray-700.leading-tight',
								{
									class: labelBig ? 'text-base' : 'text-sm',
								},
								label
							),
						]
					),
				]),
				desc && m('p.text-gray-500.text-sm.ml-8', desc),
			])
		},
	}
}
