import { Breadcrumb, Tabs, Card, Table, Cell, Button, Pagination, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'

import { ViewingTab } from './ViewingTab'
import { getProfile } from 'api/profile'
import { getVoterFilmsSummary } from 'api/voterfilms'

export const Viewing = ({ attrs: { tab, subteam, categoryId } }) => {
	let email
	let profile
	getProfile().then((p) => {
		email = p.email
		profile = p
	})

	const root = '/viewing'
	let ready

	if (!tab) {
		tab = 'all-films'
	}

	let tabs = []
	let activeTab
	const loadData = () => {
		getVoterFilmsSummary().then((res) => {
			tabs = res.map((t, ix) => {
				return {
					id: ix + 1,
					name: t.category + (t.subTeam ? ` - ${t.subTeam}` : ''),
					active: (t.category + (t.subTeam ? ` - ${t.subTeam}` : '')).toLowerCase().replaceAll(' ', '-') === tab,
					badge: t.total,
					category: t.category,
					type: t.type,
					subteam: t.subTeam,
				}
			})
			tabs.push({
				id: 0,
				name: 'All Films',
				active: 'all-films' === tab,
				type: 'Type',
				category: 'All',
				subteam: null,
			})
			activeTab = tabs.find((t) => t.active)
			ready = true
		})
	}
	loadData()

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Viewing',
						},
					],
				}),
				m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
					m('.p-4.w-full', [
						m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', [
							ready &&
								m('div.bg-gray-50.px-2.py-1', [
									m(Tabs, {
										tabs,
										onTabChange: (tabId) => {
											activeTab = null
											subteam = null
											categoryId = null
											tabs.forEach((t) => {
												if (t.id === tabId) {
													tab = t.name
													activeTab = t
													t.active = true
												} else {
													t.active = false
												}
											})
											m.redraw()
										},
									}),
									m('.relative', [
										[
											activeTab
												? m(ViewingTab, {
														key: activeTab.id,
														tab: activeTab,
														subteam: activeTab.subteam,
														categoryId,
												  })
												: null,
										],
									]),
								]),
						]),
					]),
				]),
			]
		},
	}
}
