import { request } from './request'

export const getVersion = () => {
	return m.request({
		url: `https://${process.env.API_DOMAIN}/version`,
		method: 'GET',
	})
}

const Config = flyd.stream(false)

export const getConfig = () => {
	return new Promise((resolve, reject) => {
		if (!Config()) {
			request({
				url: `voting/config`,
				method: 'GET',
			}).then((z) => {
				Config(z)
				resolve(z)
			})
		} else {
			resolve(Config())
		}
	})
}
