import { Tabs, Table, Row, Cell, CellBool, Button, Svg, LoadingFullScreen } from 'components'
import { getRoundVotingCategoryEntries } from 'api/rounds'
import groupBy from 'lodash.groupby'
import { DateTime } from 'luxon'
import chevronDown from 'assets/icons/outline/chevron-double-down.svg'

export const VotingTab = ({ attrs: { id, roundVotingCategory, roundOpen, profile } }) => {
	const root = `/rounds/${id}/votes`
	let list
	let activeTab = 1
	roundOpen = true
	let uBTrainingComplete = true
	let tabs = [
		{ name: 'Rules & Information', id: 1, active: true },
		{ name: 'Entries', id: 2 },
		{ name: 'Votes', id: 3 },
	]
	const loadData = () => {
		list = null
		getRoundVotingCategoryEntries(id, roundVotingCategory.id).then((res) => {
			list = res
		})
	}

	loadData()
	return {
		view() {
			return m('.h-auto', [
				m(Tabs, {
					tabs,
					onTabChange: (tabId) => {
						activeTab = null
						tabs.forEach((t) => {
							if (t.id === tabId) {
								activeTab = t
								t.active = true
							} else {
								t.active = false
							}
						})
						m.redraw()
					},
				}),
				m('.relative.p-6.mb-2.bg-white', [
					[
						activeTab === 1
							? m('.grid.lg:grid-cols-3', [
									m('.lg:col-span-2', [
										m('.text-lg', 'Rules'),
										roundOpen &&
											m(
												'p.my-4',
												'Enter your votes by selecting an entry against the preference, 1 being your highest preference. Any additional documents for an entry will be listed below the film in the entry list tab, just click to view them. You can only give a vote for a film you have seen and are not conflicted on.'
											),
										m('ul.list-disc.ml-6', [
											roundVotingCategory.roundVotingCategoryRules.map((item) => {
												return m(
													'li.mb-2',
													{
														class:
															uBTrainingComplete && item.rule.id === 1019 && !profile.isUBTrainingDone
																? 'text-danger'
																: '',
													},
													item.rule.description
												)
											}),
										]),
									]),
									m('.bg-gray-100', [
										m('h3', 'Status'),
										m('table.table', [
											m('tr', [m('th', 'Entries'), m('td.text-right', vnode.state.rjrvc.VoterSC.TotalEntries)]),
											m('tr', [m('th', 'Films'), m('td.text-right', vnode.state.rjrvc.VoterSC.Films)]),
											m('tr', [
												m('th', 'Films required to vote'),
												m('td.text-right', vnode.state.rjrvc.VoterSC.Needed),
											]),
											m(
												'tr',
												{
													className:
														vnode.state.seen < vnode.state.rjrvc.VoterSC.Needed ? 'table-warning' : 'table-success',
												},
												[m('th', 'Films seen'), m('td.text-right', vnode.state.rjrvc.VoterSC.SeenNotConflicted)]
											),
											m('tr', [m('th', 'Films conflicted'), m('td.text-right', vnode.state.rjrvc.VoterSC.Conflicted)]),
											m(
												'tr',
												{
													className: vnode.state.rjrvc.IsAbstaining
														? 'table-warning'
														: vnode.state.rjrvc.IsVotingForNone
														? 'table-primary'
														: '',
												},
												[
													m('th', 'Votes cast'),
													m(
														'td.text-right',
														vnode.state.rjrvc.IsAbstaining
															? 'Abstaining'
															: vnode.state.rjrvc.IsVotingForNone
															? 'None'
															: vnode.state.rjrvc.Votes.length
													),
												]
											),
										]),
									]),
							  ])
							: null,
					],
				]),
			])
		},
	}
}
