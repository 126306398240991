import { Layout } from 'components/Layout'
import { Login } from 'pages/Login'
import { ForgottenPassword } from 'pages/ForgottenPassword'
import { ResetPassword } from 'pages/ResetPassword'
import { SetPassword } from 'pages/SetPassword'
import { Viewing } from 'pages/Viewing'
import { Settings } from 'pages/Settings'
import { Screenings } from 'pages/Screenings'
import { VotingSettings } from 'pages/Settings/Voting'
import { VotingSettingsUpdated } from 'pages/Settings/Voting/Updated'
import { Logout } from 'pages/Logout'
import { Dashboard } from 'pages/Dashboard'
import { Film } from 'pages/Film'
import { Round } from 'pages/Round'

m.route.prefix = ''

const Routes = {
	'/register': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(Register, vnode.attrs)]
			)
		},
	},
	'/login': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
					allowDelegate: true,
				},
				[m(Login, vnode.attrs)]
			)
		},
	},
	'/forgotten-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(ForgottenPassword, vnode.attrs)]
			)
		},
	},
	'/reset-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(ResetPassword, vnode.attrs)]
			)
		},
	},
	'/set-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(SetPassword, vnode.attrs)]
			)
		},
	},
	'/': {
		render(vnode) {
			return m(
				Layout,
				m(Dashboard, {
					...vnode.attrs,
				})
			)
		},
	},
	'/viewing': {
		render(vnode) {
			return m(
				Layout,
				m(Viewing, {
					...vnode.attrs,
					key: vnode.attrs.tab ? vnode.attrs.tab : 'all',
				})
			)
		},
	},
	'/settings': {
		render(vnode) {
			return m(
				Layout,
				m(Settings, {
					...vnode.attrs,
				})
			)
		},
	},
	'/settings/voting': {
		render(vnode) {
			return m(
				Layout,
				m(VotingSettings, {
					...vnode.attrs,
				})
			)
		},
	},
	'/settings/voting/updated': {
		render(vnode) {
			return m(
				Layout,
				m(VotingSettingsUpdated, {
					...vnode.attrs,
				})
			)
		},
	},
	'/film/:id': {
		render(vnode) {
			return m(
				Layout,
				m(Film, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/screenings': {
		render(vnode) {
			return m(
				Layout,
				m(Screenings, {
					...vnode.attrs,
				})
			)
		},
	},
	'/rounds/:id': {
		render(vnode) {
			return m(
				Layout,
				m(Round, {
					key: vnode.attrs.id,
					tab: 'summary',
					...vnode.attrs,
				})
			)
		},
	},
	'/rounds/:id/:tab': {
		render(vnode) {
			return m(
				Layout,
				m(Round, {
					key: vnode.attrs.id + vnode.attrs.tab,
					...vnode.attrs,
				})
			)
		},
	},
	'/logout': Logout,
}

const DefaultRoute = '/'

export { Routes, DefaultRoute }
