import { Portal } from 'components/Portal'
import { Svg } from 'components/Svg'
import closeIcon from 'assets/close.svg'
import tickIcon from 'assets/tick.svg'
import exclamationIcon from 'assets/exclamation.svg'
import infoIcon from 'assets/info.svg'

const notifications = []

const addNotification = (config) => {
	if (
		notifications.find((x) => {
			return x.title === config.title
		})
	) {
		notifications.pop()
	}

	notifications.unshift({
		key: Math.random(),
		...config,
	})
	m.redraw()
}

export const notification = {
	success(config) {
		addNotification({
			...config,
			iconColor: 'green-500',
			icon: tickIcon,
		})
	},
	error(config) {
		addNotification({
			...config,
			iconColor: 'red-500',
			icon: exclamationIcon,
		})
	},
	info(config) {
		addNotification({
			...config,
			iconColor: 'blue-500',
			icon: infoIcon,
		})
	},
	warning(config) {
		addNotification({
			...config,
			iconColor: 'orange-500',
			icon: exclamationIcon,
		})
	},
}

const NotificationCloseButton = () => {
	return {
		view({ attrs: { onclick } }) {
			return m(
				'button.w-4.flex-shrink-0.ml-2.mt-1',
				{
					onclick,
				},
				m(
					Svg,
					{
						classes: ['w-auto', 'text-gray-700'],
					},
					closeIcon
				)
			)
		},
	}
}

const Notification = () => {
	const timeout = 4500
	let height = 0
	let notificationDom
	return {
		onbeforeremove({ dom }) {
			dom.classList.add('shrinkHeight')
			notificationDom.classList.add('slideOutRight')
			m.redraw()
			return new Promise((resolve) => {
				notificationDom.addEventListener('animationend', resolve)
			})
		},
		view({ attrs: { notification, index } }) {
			return m(
				'.relative.w-full.mb-2',
				{
					style: {
						transition: '.1s',
						height: height + 'px',
					},
				},
				[
					m(
						'.absolute.slideInLeft.bg-white.text-gray-900.shadow-lg.p-2.rounded.w-full.overflow-hidden',
						{
							oncreate({ dom }) {
								notificationDom = dom
								height = dom.clientHeight
								m.redraw()
								setTimeout(() => {
									notifications.pop()
									m.redraw()
								}, timeout)
							},
						},
						[
							m('.flex.justify-between.items-start', [
								m('.font-bold.text-gray-800.flex', [
									notification.icon &&
										m(
											Svg,
											{
												classes: [`text-${notification.iconColor}`, 'w-6', 'flex-shrink-0', 'mr-2'],
											},
											notification.icon
										),
									notification.title,
								]),
								m(NotificationCloseButton, {
									onclick() {
										notifications.splice(index, 1)
									},
								}),
							]),
							notification.description && m('.text-sm.mt-2.text-gray-700', notification.description),
							m('.absolute.right-0.bottom-0.bg-gray-800', {
								oncreate({ dom }) {
									const createdAt = new Date().getTime()
									let timeLeft = timeout
									setInterval(() => {
										timeLeft = timeout - (new Date().getTime() - createdAt)
										const progressWidth = (timeLeft / timeout) * 100
										dom.style.width = progressWidth + '%'
									}, 50)
								},
								style: {
									transition: '.1s',
									width: '100%',
									height: '2px',
								},
							}),
						]
					),
				]
			)
		},
	}
}

export const DisplayNotifications = () => {
	return {
		view() {
			return m(Portal, [
				m('.absolute.flex.flex-col.top-0.right-0.w-4/5.mt-1.mr-2.z-50.max-w-md', [
					notifications.map((n, i) => {
						return m(Notification, {
							key: n.key,
							index: i,
							notification: n,
						})
					}),
				]),
			])
		},
	}
}
